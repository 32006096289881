.c-btn {
    font-weight: 700;
    line-height: 1.5;
    padding: pxToRem(12) pxToRem(16) pxToRem(10);
    border-width: pxToRem(2);
    cursor: pointer;
}

.c-btn--border-inverse {
    &.is-active {
        background-color: $btn-bg-color;
        color: $btn-color;
    }
}

.c-btn--inverse {
    @include build-btn($btn-color, $btn-bg-color);

    &.is-active {
        background-color: $btn-color;
        color: $btn-bg-color;
    }
}
