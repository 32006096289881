/* Module: S */
.c-chatbot {
  &__container {
    right: 10%;
    width: auto;
    left: auto;
  }
}

.c-btn__container {

  .c-news__more {
    width: pxToRem(365);
  }
}