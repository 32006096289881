/* Module: S */
.c-accordion__title {
  padding: pxToRem(36) pxToRem(20) pxToRem(32);
}

.c-accordion__angle {
  right: pxToRem(20);
  top: pxToRem(35);

  .c-icon {
    @include sq-font-size(.875);
  }
}
