/* Module: S */
// Mixins
@mixin sq-link--default($color: $link-color-main) {
  color: $color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@mixin sq-link--animated-underline($color: $link-color-main) {
  color: $color;
  position: relative;
  text-decoration: none;

  &::before {
    @include sq-transition(width $transition-default);
    background-color: $color;
    bottom: 0;
    content: '';
    height: pxToRem(1);
    position: absolute;
    width: .0001%;
  }

  &:hover {
    &::before {
      background-color: $color;
      content: '';
      width: 100%;
    }
  }
}

@mixin sq-link--reveal($color1: $black, $color2: $white) {
  color: $color2;
  font-weight: 700;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;

  &::before {
    color: $color1;
    content: attr(data-hover);
    position: absolute;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
  }

  &:hover::before,
  &:focus::before {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0;
  }
}

@mixin sq-link--more-css($color: $link-color-main) {
  @include sq-link--animated-underline($color);

  &::after {
    background-image: url('mysource_files/arrow-right.svg');
    background-repeat: no-repeat;
    background-size: 1.2;
    content: '';
    height: 12px;
    margin-top: -6px;
    position: absolute;
    right: -15px;
    top: 50%;
    transition: transform .3s;
    width: 12px;
  }

  &:hover::after {
    transform: translateX(5px);
  }
}

@mixin sq-link--more-svg($color: $link-color-main) {
  @include sq-link--animated-underline($color);

  .e-link--more-svg-icon {
    bottom: pxToRem(3);
    display: inline-block;
    line-height: 0;
    position: absolute;
    right: pxToRem(-15);
    transition: transform .3s;
  }

  &:hover .e-link--more-svg-icon {
    transform: translateX(5px);
  }

  svg {
    color: $color;
    font-size: pxToRem(12);
  }
}

.e-link--no-underline {
  text-decoration: none;

  &::before {
    content: none !important;
  }
}

// Styls - this needs to be removed on production
.header-example {
  background-color: #999;
  padding: pxToRem(30) 0;
}

.nav-example {
  background-color: #666;
  padding: pxToRem(10) 0;
}

.footer-example {
  background-color: #ccc;
  padding: pxToRem(20) 0;
}

// Styles
a {
  @include sq-link--default;

  &.e-link--underline {
    @include sq-link--animated-underline($link-color-header);
  }

  &.e-link--reveal {
    @include sq-link--reveal($link-color-nav, $link-color-nav-hover);
  }

  &.e-link--more-css {
    @include sq-link--more-css($link-color-footer);
  }

  &.e-link--more-svg {
    @include sq-link--more-svg($link-color-footer);
  }

  .header-example & {
    @include sq-link--animated-underline($link-color-header);
  }

  .nav-example & {
    @include sq-link--reveal($link-color-nav, $link-color-nav-hover);
  }

  .footer-example & {
    @include sq-link--animated-underline($link-color-footer);
  }

  // .more-link-example & {
  //   @include sq-link--more($link-color-footer);
  // }
}
