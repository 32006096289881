/* Module: S */
.c-results__item {
  padding: pxToRem(22) 0 pxToRem(18);
}

.c-results__title {
  @include sq-font-size(1.375);
}

.c-results__description {
  margin: pxToRem(14) 0 pxToRem(9);
}

.c-results {
  .c-breadcrumbs__link {
    @include sq-font-size(.875);
  }
}

.c-results__tools {
  margin-top: pxToRem(20);
  margin-bottom: pxToRem(-18);
}

.c-results__tools-list {
  @include sq-flex-basis(auto);
}

.c-results__tools-item {
  @include sq-flex-basis(auto);
}
