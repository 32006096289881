/* Module: S */
.c-breadcrumbs__svg-mobile {
  display: none;
}

.c-breadcrumbs__svg {
  display: block;
}

.c-breadcrumbs__link {
  @include sq-font-size(1);
  display: inline-block;
  line-height: 1.5;
}
