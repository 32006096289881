/* Module: S */
#sq-google-translate-element {
  box-sizing: content-box;
  padding: 0;
  position: absolute;
  right: 0;
  top: calc(-85% - 2.5rem);
  width: pxToRem(144);

  .goog-te-gadget {
    @include sq-font-size(.625);
  }
  .goog-te-combo,
  .goog-logo-link {
    @include sq-font-size(.75);
  }

  .goog-te-gadget {
    > div {
      border-width: pxToRem(1);
      margin-bottom: pxToRem(6);
      padding: pxToRem(4) pxToRem(7);
      width: 100%;
    }
  }

  .goog-logo-link {
    img {
      width: auto;
      height: pxToRem(10);
    }
  }

  .google-translate__svg-down-arrow {
    display: none;
  }
}
