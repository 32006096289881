.ui-autocomplete {
    background-color: $desert-storm;
    border: pxToRem(1) solid $wild-sand;
    color: $primary-color;
    list-style: none;
    margin: 0;
    padding: 0 pxToRem(20);
    position: absolute;
    text-align: left;
    text-transform: none;
    z-index: 1;
    p {
        @include sq-font-size(.875);
        margin: 0;
    }
    .p-main-nav__search & {
        background-color: $white;
    }
}

.ui-menu-item {
    border-top: pxToRem(1) solid rgba($gainsboro, .4);
    padding: pxToRem(16) 0 pxToRem(14);
    &:first-of-type {
        border-top: none;
    }
}

.ui-menu-item-wrapper {
    font-weight: 400;
    @include sq-font-size(.875);
    cursor: pointer;
}

.autocomplete-keyword-selected a {
    color: $ocean-boat-blue;
}

// Home search
.s-home {
    .ui-menu-item {
        padding: pxToRem(18) 0 pxToRem(16);
    }
}
