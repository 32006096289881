/* Module: S */
// General
// ---------------------------------
.c-side-nav {
  margin-bottom: pxToRem(50);
}

.c-side-nav__heading {
  line-height: 1.14;
  padding-bottom: pxToRem(34);
  padding-top: pxToRem(30);

  h1, h2, h3, h4, h5, h6 {
    @include sq-font-size(1.375);
  }
}

.c-side-nav__trigger-svg {
  top: pxToRem(32);
}

.c-side-nav__list {
  padding: pxToRem(28) pxToRem(23) pxToRem(18);
}

.c-side-nav__item {
  padding: pxToRem(18) 0;
}

.c-side-nav__link {
  @include sq-font-size(1);
}

// Customise: Site's side nav
// ---------------------------------
.c-side-nav {
  .c-accordion__title-inner {
    @include sq-font-size(1);
  }

  .c-accordion__angle {
    top: pxToRem(24);
  }

  .e-link--more-svg {
    @include sq-font-size(.875);

    .e-link--more-svg-icon {
      top: pxToRem(16);
    }
  }
}



