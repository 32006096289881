// Accordions
.c-accordion {
    background-color: $near-white;
    border: none;
    box-shadow: 0 0 15px 5px $near-white;
}

.c-accordion__item {
    &:not(:last-child) {
        border: none;
        margin-bottom: pxToRem(10);
    }

    .c-accordion:not(.js-is-tabs) & {
        &::after {
            content: url("mysource_files/sprite.svg#angle-down");
        }
    }
}

.c-accordion__title {
    @include sq-font-size(1);
    @include sq-transition(background-color $default-transition);
    color: $primary-color;
    height: auto;
    line-height: 1.25;
    margin: 0;
    padding: pxToRem(25) pxToRem(20) pxToRem(24);
    position: relative;

    .c-accordion__item.is-active &,
    .c-accordion__item & {
        background-color: $white;
    }

    .c-accordion__item.is-active & {
        color: $secondary-color;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include sq-transition(color $default-transition);
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        margin: inherit;
    }

    h3 {
        padding: 0; // override accordion - global.scss
        word-break: break-word;
        padding-right: pxToRem(25);

        &::after {
            content: none;
        }
    }
}

.c-accordion__angle {
    @include sq-transition(transform $default-transition);
    position: absolute;
    right: pxToRem(14);
    top: pxToRem(26);

    .c-icon {
        @include sq-font-size(0.8125);

        .c-accordion__item.is-current & {
            fill: $secondary-color;
        }
    }

    .c-accordion__item.is-active & {
        @include sq-transform(rotate(180deg));
    }
}

a.c-accordion__title {
    text-decoration: none;
}

.c-accordion__panel {
    @include sq-font-size(0.875);
    @include sq-transition(
        max-height $default-transition,
        visibility $default-transition
    );
    background-color: $alabaster;
    color: $primary-color;
    line-height: 1.86;
    visibility: hidden;

    .is-active > &,
    .is-current > & {
        visibility: visible;
    }
}

.c-accordion__panel__inner {
    padding: pxToRem(20);
}

// For Tabs
// ----------------------------------------------
.c-accordion__item {
    .c-accordion.js-is-tabs & {
        width: 100%;
    }

    .js-tabs-enabled:not(.js-is-tabs) &:not(:last-child) {
        margin-bottom: 0;
        border-bottom: pxToRem(1) solid $alabaster;
    }

    .c-accordion.js-is-tabs &,
    .c-accordion.js-is-tabs &:not(:first-child) {
        // override global scss
        border-left: none;
    }
}

.c-accordion__title {
    .js-is-tabs & {
        padding: pxToRem(25) pxToRem(20) pxToRem(19);
    }

    .c-accordion.js-is-tabs & {
        background-color: $white;
        border-color: transparent;
        border-left-color: rgba($iron, 0.4);
        border-radius: 0;
        color: $primary-color;

        &:hover {
            background-color: $white;
        }
    }

    .js-tabs-enabled .is-active & {
        background-color: $primary-color;
    }

    .c-accordion.js-is-tabs .c-accordion__item:first-of-type &, /* override global.scss*/
  .c-accordion.js-is-tabs .c-accordion__item.is-current + .c-accordion__item & {
        border-left-color: transparent;
    }

    .c-accordion.js-is-tabs .c-accordion__item.is-current & {
        // override global.scss
        background-color: $primary-color;
        border-color: transparent;
        color: $white;
    }
}

.c-accordion__title {
    .js-tabs-enabled .is-active & {
        color: $white;
    }

    .js-is-tabs .c-accordion__item:hover & {
        color: $secondary-color;
    }

    .js-is-tabs .c-accordion__item.is-current & {
        color: $white;
    }
}

.c-accordion__panel {
    .c-accordion.js-is-tabs & {
        border-color: transparent;
        border-top-color: rgba($iron, 0.4);
        z-index: 1;
    }
}

.c-accordion__angle {
    .js-is-tabs & {
        display: none;
    }

    .js-tabs-enabled .is-active & {
        @include sq-transform(rotate(0));

        .c-icon {
            fill: $white;
        }
    }
}
