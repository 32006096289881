.p-social {
  &__container {
    background: $navy-blue;
  }

  &__inner {
    padding: pxToRem(48) 0 pxToRem(65) 0;

    @media #{$mq-medium} {
      padding: pxToRem(62) 0 pxToRem(83) 0;
    }
  }

  &__inner-box {
    display: none;
    &.is-tab-active {
      display: block;
    }
  }

  &__header {
    font-size: pxToRem(22);
    font-weight: bold;
    text-align: center;
    color: $white;
    position: relative;
    p:after {
      background-color: #0768ac;
      bottom: pxToRem(-14);
      content: '';
      height: pxToRem(3);
      left: 43%;
      position: absolute;
      width: pxToRem(30);
      opacity: .6;

      @media #{$mq-medium} {
        left: 46%;
      }

      @media #{$mq-large} {
        left: 48%;
      }
    }
  }

  &__icons {
    margin: pxToRem(40) 0;
  }

  &__list {
    text-align: center;
  }

  &__icon-item {
    display: inline-block;
    margin: 0 pxToRem(6.4);
    & .js-social__link {
      display:  inline-block;
      width: pxToRem(45);
      height:  pxToRem(45);
      border: 2px solid $navy-blue;
      border-radius: 50%;
      -webkit-transition : border-color 0.2s ease;
      -moz-transition : border-color 0.2s ease;
      -o-transition : border-color 0.2s ease;
    }

    &.is-tab-active .js-social__link {
      border-color: $white;
    }
  }

  &__content {
    @media #{$mq-large} {
      @include sq-flex-box();
    }
  }

  &__item {
    background:$white;
    margin: 0 pxToRem(5);
    padding: pxToRem(40) pxToRem(30);
    height: pxToRem(311);
    overflow: hidden;
    position: relative;

    &__social-img {
      margin-top: 29%;
    }

    &.slick-current {
      height: auto;
    }

    @media #{$mq-large} {
      margin: 0 pxToRem(10);
      height: inherit;
      min-height: pxToRem(380);
      @include flex-grow(1);
      @include sq-flex-basis(0);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.youtube {
      height: inherit;
    }

    .c-icon {
      color: $navy-blue;
      font-size: 2.5rem;
    }

    &__title {
      margin-top: pxToRem(10);
      font-size: pxToRem(18);
    }
  }

  &__item__header {
    display: inline-block;
    margin-bottom: pxToRem(10);
    &:hover,
    &:focus {
      text-decoration: none;
      cursor: pointer;
    }
    img {
      display: inline-block !important;
    }

    &.youtube {
       @include sq-flex-box();
    }
  }

  &__profile {
    background-color: $navy-blue;
    @include sq-border-radius(50px);
    vertical-align: bottom;
    &.facebook {
      padding: pxToRem(7) pxToRem(12);
    }
    &.twitter {
      padding: pxToRem(8) pxToRem(6);
    }
  }

  &__date {
    margin-left: pxToRem(5);
    font-weight: bold;
    display: inline-block;
    color: $navy-blue;
    font-weight: 700;
    font-size: pxToRem(18);

    &.youtube {
      margin-top: pxToRem(9);
      margin-left: pxToRem(15);
    }
  }

  &__content__link {
    font-size: pxToRem(16);
    font-weight: 500;
  }

  &__video {
    iframe {
      display: inline-block;
      width: 100%;
      height: pxToRem(170);
    }
  }
}

// custom slick slide style;
.p-social_media--home {
  .o-container {
    padding-left:0;
    padding-right: 0;
    max-width: inherit;
    @media #{$mq-large} {
      max-width: $default-container-max-width;
      padding-left: $default-container-padding;
      padding-right: $default-container-padding;
    }
  }

  .slick-track {
    display: block;
    box-sizing: border-box;
  }

  .slick-dots {
    bottom: pxToRem(-72);
  }

  .slick-dots button {
    border: none;
    background-color: $mercury;
  }

  .slick-dots .slick-active button {
    background-color: $white !important;
    border-color: $white !important;
  }

  .slick-slide
  {
    width: pxToRem(280);
    @media #{$mq-medium} {
      width: pxToRem(380);
    }
  }
}
