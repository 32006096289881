// REM font sizing with pixel fall back
// This technique will allow external font sizes to be set for html and respected
// if REMs are supported (everything but IE8 and older).
@mixin sq-font-size($sizeValue: 1) {
    @include sq-rem-attr(font-size, $sizeValue);
}

// Express the value of an attribute using rems falling back to pixels
@mixin sq-rem-attr($attr, $sizeValue: 1) {
    #{$attr}: ($sizeValue * $base-font-pixel) + px;
    #{$attr}: $sizeValue + rem;
}

// Transition shortcut
@mixin sq-transition($params...) {
    -webkit-transition: $params;
    -moz-transition: $params;
    transition: $params;
}

// Apply a prefixed transformation
@mixin sq-transform($params) {
    -webkit-transform: $params; // Chrome, Safari 3.1+
    //-moz-transform: $params; // Firefox 3.5-15
    -ms-transform: $params; // IE 9
    transform: $params;
}

// 2D rotation with IE support
@mixin sq-rotate($deg) {
    $msRotVal: (
        ((360 + $deg) % 360) / 90
    ); // Modulo lets us handle negative values.
    @include sq-transform(rotate($deg + deg));
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$msRotVal});
}

@mixin sq-border-box() {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin sq-reset-box-sizing() {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

@mixin sq-box-shadow($params) {
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}

// Glow effect taken from foundation
@mixin sq-block-glowing-effect(
    $selector: focus,
    $fade-time: 300ms,
    $glowing-effect-color: blue
) {
    -webkit-transition: -webkit-box-shadow $fade-time,
        border-color $fade-time ease-in-out;
    -moz-transition: -moz-box-shadow $fade-time,
        border-color $fade-time ease-in-out;
    transition: box-shadow $fade-time, border-color $fade-time ease-in-out;

    &:#{$selector} {
        @include sq-box-shadow(0 0 5px $glowing-effect-color);
        border-color: $glowing-effect-color;
    }
}

// Legacy border radius helper
@mixin sq-border-radius($radius: 4px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

// Single side border-radius

@mixin border-top-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}
@mixin border-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    background-clip: padding-box;
}
@mixin border-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

// Opacity with IE filter fallback
// Value should be expressed as a number between 0 and 100
// with 0 being transparent and 100 being opaque
@mixin sq-opacity($value) {
    $decimal-value: $value/100;
    -webkit-opacity: $decimal-value;
    -moz-opacity: $decimal-value;
    opacity: $decimal-value;
    -ms-filter: #{"alpha(opacity=" + $value + ")"};
    filter: alpha(opacity=$value);
}

// Individual border radius rule helper.
@mixin sq-rounded($vert, $horz, $radius: 10px) {
    -webkit-border-#{$vert}-#{$horz}-radius: $radius;
    -moz-border-radius-#{$vert}#{$horz}: $radius;
    border-#{$vert}-#{$horz}-radius: $radius;
}

// Use an SVG background image (for perfect Retina-rendering) with a PNG fallback.
@mixin sq-svg-background($suffixless-url) {
    background-image: url($suffixless-url+".svg");
    .no-svg & {
        background-image: url($suffixless-url+".png");
    }
}

@mixin sq-flex-box() {
    .no-js &,
    .no-flexbox & {
        display: table;
    }
    .flexbox & {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }
}

@mixin sq-flex-direction($value: row) {
    -webkit-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin sq-justify-content($value: flex-start) {
    @if $value==flex-start {
        -ms-flex-pack: start;
    } @else if $value==flex-end {
        -ms-flex-pack: end;
    } @else if $value==space-between {
        -ms-flex-pack: justify;
    } @else if $value==space-around {
        -ms-flex-pack: distribute;
    } @else {
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    justify-content: $value;
}

@mixin sq-flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    -ms-flex-preferred-size: $value;
    flex-basis: $value;
}

@mixin flex-grow($grow) {
    -webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
    flex-grow: $grow;
}

@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}
// Apply flex to an element
@mixin sq-flex($values) {
    // Table cell is a fallback to no flexbox support
    .flexbox & {
        -webkit-box-flex: $values;
        -moz-box-flex: $values;
        -webkit-flex: $values;
        -ms-flex: $values;
        flex: $values;
    }
    .no-js &,
    .no-flexbox & {
        display: table-cell;
        // Vertical align is needed to prevent auto vertical centering by some browsers
        vertical-align: top;
    }
}

// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
    -webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
    flex-shrink: $shrink;
}

// Em calculator based on James' in Squiz Map issue #4906
@mixin sq-calc-ems($prop, $numerator, $denominator) {
    $tmp: ($numerator/$denominator);
    #{$prop}: #{$tmp}em;
    #{$prop}: calc(#{$numerator}em / #{$denominator});
}

// CSS arrow helper
// $direction: top, left, right, bottom
@mixin sq-arrow($direction: top, $color: #000, $size: 5px, $height: 0px) {
    $_height: $size + $height;

    height: 0;
    width: 0;
    content: " ";
    border-style: solid;

    @if $direction == "top" {
        border-width: $size $size $_height $size;
        border-color: transparent transparent $color transparent;
    } @else if $direction == "right" {
        border-width: $size $size $size $_height;
        border-color: transparent transparent transparent $color;
    } @else if $direction == "bottom" {
        border-width: $_height $size $size $size;
        border-color: $color transparent transparent transparent;
    } @else if $direction == "left" {
        border-width: $size $_height $size $size;
        border-color: transparent $color transparent transparent;
    }
}

@mixin sq-inline-block() {
    display: inline-block;
    *display: inline;
    zoom: 1;
}

@mixin sq-list-reset() {
    margin: 0;
    padding: 0;
    list-style: none;
}

// "Quantity queries" - a.k.a. "One of n siblings"
// From this codepen: http://codepen.io/long-lazuli/pen/PwBbmo
// http://alistapart.com/article/quantity-queries-for-css
@mixin sq-one-of-n-siblings($countArg...) {
    &:nth-last-child(#{$countArg}):first-child,
    &:nth-last-child(#{$countArg}):first-child ~ & {
        @content;
    }
}

@mixin sq-keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin sq-skew($deg) {
    -webkit-transform: skew($deg); // Safari
    -ms-transform: skew($deg); // IE 9
    transform: skew($deg);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $left $top $blur $color;
    -moz-box-shadow:inset $left $top $blur $color;
    box-shadow:inset $left $top $blur $color;
    } @else {
      -webkit-box-shadow: $left $top $blur $color;
      -moz-box-shadow: $left $top $blur $color;
      box-shadow: $left $top $blur $color;
    }
  }
/* Font face
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}*/
