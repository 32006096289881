/* Module: S */
a {
  &.e-link--more-svg {
    @include sq-font-size(1.25);
    line-height: 1;
  }
}

.e-link--more-svg-icon {
  top: pxToRem(36);
}
