// General
// ---------------------------------
.c-side-nav {
    margin-bottom: pxToRem(10);
}

.c-side-nav__heading {
    background-color: $primary-color;
    color: $white;
    display: block;
    line-height: 1.56;
    padding: pxToRem(18) pxToRem(35) pxToRem(17) pxToRem(22);
    position: relative;

    &:hover {
        text-decoration: none;
    }
}

.c-side-nav__heading {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit;
        margin: 0;
    }
}

.c-side-nav__trigger-svg {
    @include sq-transform(rotate(90deg));
    @include sq-transition(transform $default-transition);
    display: none;
    height: pxToRem(19);
    position: absolute;
    right: pxToRem(20);
    top: pxToRem(18);
    width: pxToRem(19);

    .c-icon {
        @include sq-font-size(1.1875);
        fill: $tertiary-color;
    }

    .c-side-nav__heading.js-dropdown-trigger & {
        display: block;
    }

    .is-active .c-side-nav__heading & {
        @include sq-transform(rotate(-90deg));
    }

    .js-dropdown-disabled & {
        display: none !important;
    }
}

.c-side-nav__body {
    background-color: $white;
    box-shadow: 0 pxToRem(5) pxToRem(15) pxToRem(5) $near-white;
    overflow: hidden;
}

.c-side-nav__list {
    list-style: none;
    padding: pxToRem(20) pxToRem(23);
    margin: 0;
}

.c-side-nav__item {
    border-top: pxToRem(1) solid rgba($mercury, 0.4);
    padding: pxToRem(16) 0;

    &:first-of-type {
        border-top: none;
    }
}

.c-side-nav__link {
    font-weight: 700;
    @include sq-font-size(1);
    @include sq-transition(color $default-transition);
    line-height: 1.25;

    &:hover {
        color: $secondary-color;
        text-decoration: none;
    }

    .c-side-nav__item.is-active > & {
        color: $secondary-color;
    }
}

// Customise: Site's side nav
// ---------------------------------
.c-side-nav {
    .c-accordion__item {
        margin-bottom: 0;
    }

    .c-accordion__title {
        padding: 0 pxToRem(20);
    }

    .c-accordion__title-inner {
        border-top: pxToRem(1) solid rgba($mercury, 0.4);
        padding: pxToRem(25) 0 pxToRem(24);
    }

    .c-accordion__angle {
        right: pxToRem(20);
    }

    .c-accordion__panel__inner {
        padding-bottom: 0;
        padding-top: 0;
    }

    .e-link--more-svg {
        @include sq-font-size(0.875);
        background-color: inherit;
        border-top: pxToRem(1) solid $mercury;
        color: $primary-color;
        padding: pxToRem(18) 0;
        padding-right: pxToRem(16);

        &:first-of-type {
            border-top: none;
        }

        .e-link--more-svg-icon {
            right: 0;
            top: pxToRem(18);
        }

        &:hover {
            background-color: inherit;
            color: $secondary-color;
        }
    }
}

.c-accordion__title-inner {
    .c-accordion__item:first-of-type &,
    .c-accordion__item.is-active + .c-accordion__item & {
        border-top: none;
    }
}

.c-side-nav__body {
    .c-accordion {
        background-color: inherit;
        box-shadow: none;
        padding: pxToRem(9) 0 pxToRem(9);
    }
}

.c-side-nav__level-1-text {
    @include sq-transition(color $default-transition);
    &:hover {
        color: $secondary-color;
    }
}





// logo

.header-logo{
    display: inline-block;
    position: relative;
  }
  
   .logo-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 7px;
  }
  
  @media screen and (max-width: 959px) {
    .logo-wrapper{
      max-width: 3rem;
      margin-top: pxToRem(-14);
    }
  }
  
  .nsw-header__waratah{
    display:flex;
    justify-content:center
  }
  @media (min-width:62rem){
    .nsw-header__waratah{
        padding:0
    }
  }
  .nsw-header__waratah svg{
    height:3rem;
    display:block
  }
  @media (min-width:62rem){
    .nsw-header__waratah svg{
        height:4.75rem
    }
  }

  

  .nsw-header__waratah a{
    display:flex;
    text-decoration:none;
    align-items:center;
    width: 72px;
    height: 76px;
  }

  @media (max-width:62rem){
    .nsw-header__waratah a{
      width: pxToRem(56);
    }
  }


  .nsw-header__waratah a:hover{
    background:transparent;
    outline-width:0
  }

  .nsw-header__waratah a:focus{
    outline-width:3px
  }
  @media (min-width:62rem){
    .nsw-header__waratah a:focus{
        outline:none
    }
  }
  @media (min-width:62rem){
    .nsw-header__waratah a:after{
        z-index:100;
        content:"";
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0
    }
  }
  .nsw-header__waratah a svg:not(:first-child){
    margin-left:1.5rem
  }
  @media (min-width:62rem){
    .nsw-header__waratah a svg:not(:first-child){
        margin-left:3rem
    }
  }
  .nsw-header__name{
    padding:.75rem 0;
  }

  .nsw-header__name{
    display: none;
  }
  
  @media (min-width:62rem){
    .nsw-header__name{
        padding:0;
        padding: 0;
        margin-left: 6.85rem;
        margin-top: -3rem;
        display: inline-block;
    }
  }
  .nsw-header__title{
    /* font-size:var(--nsw-font-size-lg-mobile);
    line-height:var(--nsw-line-height-lg-mobile);
    font-weight:var(--nsw-font-bold);
    color:var(--nsw-brand-dark); */
    font-size: 1.625rem;
    display:flex;
    align-items:center
  }
  
  @media (min-width:62rem){
    .nsw-header__title{
     font-size: pxToRem(24);
     font-weight: 700;
     color: #002664;
     
    }
  }
  .nsw-header__title .nsw-tag{
    margin-left:1.5rem
  }
  .nsw-header__description{
    /* font-size:var(--nsw-font-size-xs-mobile);
    line-height:var(--nsw-line-height-xs-mobile); */
    color:var(--nsw-brand-dark)
  }
  @media (min-width:62rem){
    .nsw-header__description{
        /* font-size:var(--nsw-font-size-xs-desktop);
        line-height:var(--nsw-line-height-xs-desktop) */
    }
  }
  .nsw-header__logo{
    display:flex;
    justify-content:center
  }
  @media (min-width:62rem){
    .nsw-header__logo{
        padding-left:1rem;
        justify-content:flex-start
    }
  }
  .nsw-header__logo svg{
    height:3rem;
    display:block
  }
  @media (min-width:62rem){
    .nsw-header__logo svg{
        height:4.75rem
    }
  }