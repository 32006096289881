/* Module: S */
// Placeholders

$hero-height-small: pxToRem(487);
$hero-height-large: pxToRem(630);

// Placeholders

%hero-height {
  height: $hero-height-small;

  @media #{$mq-large} {
    height: $hero-height-large;
  }
}

// Styles

.p-hero--home {
  background-color: $primary-color;
  position: relative;
}

.p-hero--home__slider.slick-slider {
  margin: 0;

  .c-slider__dots {
    bottom: pxToRem(20);

    @media #{$mq-large} {
      bottom: pxToRem(50);
    }
  }

  .c-slider__arrow {
    color: $white;
    &--next {
      right: $default-container-padding;
    }
  }
}

.p-hero--home__slide {
  @extend %hero-height;

  background-size: cover;
  background-position: center center;
  &::before {
    background:rgba(0,0,0,0.3);
    content: '';
    height: 100%;
    position: absolute;
    transition: all .3s ease;
    width: 100%;
  }

  &.is-hero-image-loaded {
    &::before {
      opacity: 1;
    }
  }
}

.p-hero--home__slide__inner {
  @include sq-font-size(1);
  position: relative;
  color: $white;
  -webkit-display: flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 90%;
  text-align: center;
  
  p {
    text-transform: uppercase;
  }

  @media #{$mq-medium} {
    max-width: inherit;
  }
  @media #{$mq-large} {
    max-width: 90%;
    @include sq-font-size(1.25);
    max-width: pxToRem(825);
  }
}

.p-hero--home__heading {
  color: $white;
  font-size: pxToRem(18);
  font-weight: $font-light;
  letter-spacing: pxToRem(0.176);
  line-height: 1.24;
  margin: 0;
  padding: 0;
  
  & span:first-child {
    display: block;
  }
  @media #{$mq-large} {
    font-size: pxToRem(22);
    letter-spacing: pxToRem(-1.1);
    text-align: center;
    margin-bottom: 0;
  }
}

.p-hero--home__topic {
  font-weight: 700;
  font-size: pxToRem(14);
  @media #{$mq-large} {
    font-size: pxToRem(18);
  }
}

.p-hero--home__btn.c-btn {
  margin: .3rem pxToRem(1);
  padding: pxToRem(9.12) pxToRem(11.2);
  font-size: pxToRem(14);
  letter-spacing: 0.3px;
  background-color: transparent;
  font-weight: 500;
  border-color: $white;
  color: $white;
  @media #{$mq-large} {
    font-size: pxToRem(18);
    display: inline-block;
    margin: pxToRem(28) pxToRem(5) 0 pxToRem(5);
  }
  @media #{$mq-medium} {
    margin: pxToRem(28) pxToRem(5) 0 pxToRem(5);
    padding: pxToRem(9.12) pxToRem(16);
  }

  &:hover {
    background-color: $white;
    color: $primary-color;
    // border: none;
  }
}

.p-hero--home__pattern-overlay {
  @extend %hero-height;

  background-repeat: no-repeat;
  display: none;
  left: 0;
  position: absolute;
  top: pxToRem(95);
  width: pxToRem(280);
  z-index: 99;

  @media #{$mq-large} {
    background-image: url(mysource_files/circles-bg.png);
    display: block;
  }
}