$custom-font-semi-bold: 500;
// Font families


/*
    Copyright (C) Hoefler & Co.
    This software is the property of Hoefler & Co. (H&Co).
    Your right to access and use this software is subject to the
    applicable License Agreement, or Terms of Service, that exists
    between you and H&Co. If no such agreement exists, you may not
    access or use this software for any purpose.
    This software may only be hosted at the locations specified in
    the applicable License Agreement or Terms of Service, and only
    for the purposes expressly set forth therein. You may not copy,
    modify, convert, create derivative works from or distribute this
    software in any way, or make it accessible to any third party,
    without first obtaining the written permission of H&Co.
    For more information, please visit us at http://typography.com.
*/

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-XLight_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-XLight_Web.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-XLightItalic_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-XLightItalic_Web.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-Light_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-Light_Web.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-LightItalic_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-LightItalic_Web.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-Book_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-Book_Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-BookItalic_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-BookItalic_Web.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-Medium_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-Medium_Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-MediumItalic_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-MediumItalic_Web.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-Bold_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-Bold_Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-BoldItalic_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-BoldItalic_Web.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-Black_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-Black_Web.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'HCo Gotham SSm';
    src:url('./mysource_files/GothamSSm-BlackItalic_Web.woff2') format('woff2'),
        url('./mysource_files/GothamSSm-BlackItalic_Web.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

// font and sizing
body {
    font-family: "HCo Gotham SSm", "Open Sans", sans-serif;  
    font-weight: 400;
    color: $armadillo;
    line-height: 1.63;
}

p {
    margin: pxToRem(12) 0;
}

h1,
.e-h1,
h2,
.e-h2,
h3,
.e-h3,
h4,
.e-h4,
h5,
.e-h5,
h6,
.e-h6,
.e-intro {
    margin-bottom: pxToRem(12);
}

h1,
.e-h1 {
    font-weight: 500;
    @include sq-font-size(1.875); // 30px
    color: $primary-color;
    line-height: 1.33; //margin-bottom: pxToRem(12);
}

h2,
.e-h2 {
    font-weight: 700;
    @include sq-font-size(1.375); // 22px
    color: $primary-color;
    line-height: 1.36;
}

h3,
.e-h3 {
    font-weight: 500;
    @include sq-font-size(1.375); // 22px
    color: $dove-gray;
    line-height: 1.36;
    margin-bottom: pxToRem(28);
    position: relative;
    &::after {
        background-color: $iron;
        bottom: pxToRem(-14);
        content: "";
        height: pxToRem(3);
        left: 0;
        position: absolute;
        width: pxToRem(30);
    }
}

h4,
.e-h4 {
    font-weight: 700;
    @include sq-font-size(1.125); // 18px
    color: $primary-color;
    line-height: 1.22;
}

h5,
.e-h5 {
    font-weight: 700;
    @include sq-font-size(1); // 16px
}

h6,
.e-h6 {
    font-weight: 700;
    @include sq-font-size(0.875); // 14px
}

.e-sub-h {
    font-weight: 700;
    @include sq-font-size(0.875);
    color: $aluminum;
    line-height: 1.86;
}

.e-intro {
    font-weight: 500;
    @include sq-font-size(1.25);
    color: $primary-color;
    line-height: 1.5;
    margin: pxToRem(12) 0;
}

.e-note-and-date {
    font-weight: 400;
    @include sq-font-size(0.875);
    line-height: 1.86;
}

strong {
    font-weight: 700;
    font-weight: $custom-font-semi-bold;
}

img:not([class]) {
    display: block;
    margin: pxToRem(30) 0;
}

// ul and ol
ol:not([class]),
ul:not([class]) {
    list-style-type: none;
    li:not([class]) {
        position: relative;
        &::before {
            content: "";
            position: absolute;
        }
    }
    > li:not([class]) {
        line-height: 1.63;
        margin-bottom: pxToRem(16);
    }
    ol {
        padding-left: pxToRem(26);
    }
    ul {
        padding-left: pxToRem(17);
    }
    ol,
    ul {
        color: $primary-color;
        margin-top: pxToRem(16);
        > li:not([class]) {
            @include sq-font-size(0.875);
            line-height: 1.43;
        }
        ol {
            padding-left: pxToRem(37);
        }
        ul {
            padding-left: pxToRem(21);
        }
        ol,
        ul {
            color: $armadillo;
            margin-top: pxToRem(20);
        }
    }
}

ul:not([class]) {
    > li:not([class]) {
        &::before {
            background-color: $primary-color;
            border-radius: 50%;
            height: pxToRem(6);
            left: pxToRem(-15);
            top: pxToRem(10);
            width: pxToRem(6);
        }
    }
    ul {
        > li:not([class]) {
            &::before {
                background-color: $tertiary-color;
                top: pxToRem(7);
            }
        }
        ul {
            > li:not([class]) {
                &::before {
                    background-color: $primary-color;
                    border-radius: 0;
                    height: pxToRem(2);
                    left: pxToRem(-16);
                    top: pxToRem(9);
                }
            }
        }
    }
}

ol:not([class]) {
    counter-reset: orderlist;
    > li:not([class]) {
        &::before {
            counter-increment: orderlist;
            content: counters(orderlist, ".") " ";
            font-weight: $font-bold;
        }
    }
    > li:not([class]) {
        &::before {
            color: $tertiary-color;
            right: calc(100% + 0.75rem);
        }
    }
    ol {
        > li:not([class]) {
            &::before {
                color: currentColor;
            }
        }
        ol {
            > li:not([class]) {
                &::before {
                    color: $tertiary-color;
                    right: calc(100% + 0.9375rem);
                }
            }
        }
    }
}
