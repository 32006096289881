/* Module: S */
.p-bottom-links {
  .footer & {
    display: inline-block;
    width: 50%;
  }
}

.p-bottom-links__list {
  @include sq-flex-direction(row);
  @include flex-wrap(wrap);
}

.p-bottom-links__item {
  margin-top: 0;
  width: calc(50% - 1.25rem);
  margin-bottom: pxToRem(20);
  margin-right: pxToRem(20);
}

.p-bottom-links__link {
  @include sq-font-size(.875);
}
