// Dots
.slick-dots {
  @include sq-flex-box();
  @include sq-justify-content(center);
  bottom: 0;
  list-style: none;
  padding: 0 pxToRem(40);
  position: absolute;
  width: 100%;

  > * {
    margin: 0 pxToRem(4);
  }

  button {
    @include sq-transition(background-color $default-slide-transition, border-color $default-slide-transition);
    border: pxToRem(1) solid $primary-color;
    border-radius: 50%;
    height: pxToRem(8);
    overflow: hidden;
    padding: 0;
    text-indent: pxToRem(-999);
    width: pxToRem(8);

    &:hover {
      border-color: $tertiary-color;
    }
  }

  .slick-active button {
    background-color: $tertiary-color;
    border-color: $tertiary-color;
  }
}

// Arrows
.slick-arrow {
  background-color: inherit;
  border: 0;
  bottom: pxToRem(24);
  color: $tertiary-color;
  height: auto;
  padding: 0;
  position: absolute;
  width: auto;
  z-index: 1;

  .c-icon {
    @include sq-font-size(1.1875);
    @include sq-transition(transform $default-slide-transition);
  }
}

.slick-prev {
  left: pxToRem(20);

  &:hover .c-icon {
    @include sq-transform(translateX(-.3125rem));
  }
}

.slick-next {
  right: pxToRem(20);

  &:hover .c-icon {
    @include sq-transform(translateX(.3125rem));
  }
}


// Slides
.c-gallery__bottom-banner {
  background-color: $alabaster;
  height: pxToRem(76);
  width: 100%;
}
