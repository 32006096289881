/*
 * #page-wrapper's class for layout
 */

.s-float-sidebar {
  .o-aside {
    margin-top: pxToRem(-45);
  }
}

.s-sidebar-bottom-mobile {
  .o-layout--right-aside {
    @include sq-flex-direction(column-reverse);
  }
}

.s-sidebar-split-mobile {
  .o-content {
    .c-side-nav {
      margin: pxToRem(-45) 0 pxToRem(40);
    }
  }
  .o-aside {
    .c-side-nav {
      display: none;
    }
  }

  .o-layout--right-aside {
    padding-bottom: pxToRem(10);
  }

  .main > .p-card__container {
    margin-bottom: pxToRem(50);
  }
}

/*
 * #page-wrapper's class for pages
 */

// home
.s-home {
  .p-main-nav__search {
    display: none;
  }
}

// search
.s-search {
  .c-result-summary {
    padding: pxToRem(28) 0 pxToRem(11);
  }
  .c-pagination {
    margin: pxToRem(9) 0 pxToRem(48);
  }
  .o-content .c-search {
    margin-top: pxToRem(35);
  }
}

.s-news {
  .o-content .c-search {
    margin-top: pxToRem(35);
  }
}

// news
.s-news {
  .c-result-summary {
    border-bottom: pxToRem(1) solid $gainsboro;
    padding: pxToRem(28) 0 pxToRem(11);
  }
  .c-pagination {
    margin: pxToRem(9) 0 pxToRem(48);
  }
  .c-news__item {
    &:last-of-type {
      border-bottom: none;
    }
  }
  .c-news__header {
    display: none;
  }
  .c-news__more {
    display: none;
  }
}

/*
 * Page patterns
 */

// Header
.p-header__inner {
  height: pxToRem(80);
}

.p-header__logo {
  padding-left: pxToRem(71);
  padding-top: pxToRem(17);
}

.p-header__logo-img {
  height: pxToRem(47);
  width: pxToRem(106);
}

// Footer
.footer {
  background-color: $primary-color;
  padding: pxToRem(.1) 0;
}

/*
 * Components
 */
.c-divider {
  display: table;
  clear: both;
  margin: pxToRem(30) 0;
}

.c-panel {
  padding: pxToRem(28) 0;
}

/*
 * Elements
 */
.e-tags {
  margin: pxToRem(16) 0;
}

/*
 * Utilities
 */
.u-spread-contents {
  @include sq-flex-box();
  align-content: center;
  justify-content: space-around;
}

.u-no-margin {
  margin: 0;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-highlight {
  color: $tertiary-color;
}

.u-visuallyhidden {
  visibility: hidden !important;
}
