.c-side-links__list {
  list-style: none;
  padding-left: 0;
}

.c-side-links__item {
  border-top: pxToRem(1) solid rgba($mercury, .4);

  &:first-of-type {
    border-top: none;
  }
}

.c-side-links__link {
  @include sq-transition(color $default-transition);
  display: block;
  line-height: 1.43;
  padding: pxToRem(17) 0 pxToRem(18);
  position: relative;

  &:hover,
  .c-side-links__item.is-active & {
    color: $dove-gray;
  }

  &:hover {
    text-decoration: none;
  }
}

.e-link--more-svg-icon {
  margin-left: pxToRem(10);
  position: absolute;

  .c-side-links__link & {
    top: auto;
  }
}
