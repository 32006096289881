/* Module: S */
.p-home-search {
  width: 100%;

  .c-search__icon {
    left: pxToRem(30);
  }

  .c-search__input {
    padding: pxToRem(33) pxToRem(176) pxToRem(28) pxToRem(78);
  }
}
