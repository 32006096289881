/* Module: S */
$container-padding: pxToRem(20); // 1.25 rem

.p-home-search {
  margin: pxToRem(55) auto pxToRem(45);
  width: calc(100% - 2.5rem);

  .c-search__input {
    @include sq-font-size(1.125);
    line-height: 1;
    padding: pxToRem(33) pxToRem(176) pxToRem(28) pxToRem(63);
  }

  .c-search__btn {
    @include sq-font-size(1.375);
    line-height: 1;
    padding: pxToRem(28) pxToRem(38);
  }

  .c-search__btn-text {
    display: inline;
  }

  .c-search__svg {
    display: none;
  }

  .c-search__icon {
    display: block;
    left: pxToRem(25);
    top: pxToRem(25);

    .c-icon {
      font-size: pxToRem(31);
    }
  }
}
