.c-popup .c-popup__box {
    display: none;
}

.mfp-content .c-popup__box {
    display: block;
}

.c-popup__box {
    position: relative;
    background: #fff;
    width: auto;
    max-width: 800px;
    margin: 20px auto;

    .c-popup__box-title {
        background-color: $sapphire;
        color: $white;
        height: pxToRem(60);
        font-size: 1rem;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: pxToRem(60);
        letter-spacing: normal;
        padding-left: pxToRem(24);
    }

    .mfp-close {
        color: $orange-red;
        margin-top: pxToRem(4);
        font-family: sans-serif;
        font-size: pxToRem(30);
        font-weight: 100;
        opacity: 1;
    }

    .c-popup__box-content {
        padding: pxToRem(40) pxToRem(30);
        border-bottom: solid 1px $aluminum;
    }

    .c-popup__box-control {
        height: pxToRem(100);
    }

    .c-panel {
        text-align: center;
    }
}
