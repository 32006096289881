.c-image-text-link {

  &::after {
    background-color: $mercury;
    content: '';
    display: block;
    height: pxToRem(1);
    margin-top: pxToRem(29);
    opacity: .4;
    width: 90%;
  }
}
.c-image-text-link__image {
  margin: pxToRem(23) 0 pxToRem(3);
}

.c-image-text-link__cta {
  margin-top: pxToRem(-4);
  .e-link {
    @include sq-font-size(1.25);
  }
}
