#progress-bar {
	width: 100%;
  	background-color: #f1f1f1;
  	border-radius: pxToRem(5);
  	margin-bottom: pxToRem(70);
  	#myBar {
	  	width: 2%;
	  	height: pxToRem(10);
	  	background-color: #ee5f30;
	  	border-radius: pxToRem(5);
	  	position: relative;
	  	&:before {
	  		content: " ";
	  		position: absolute;
	  		border-radius: pxToRem(2);
	  		right: pxToRem(-5);
    		top: pxToRem(15);
	  		width:pxToRem(15);
	  		height:pxToRem(15);
	  		background-color: #002167;
	  		transform: rotate(-45deg)
	  	}
	  	#bar-indicator {
	  		position: absolute;
	  		right: pxToRem(-30);
    		top: pxToRem(18);
    		padding: pxToRem(5) pxToRem(15);
  			border-radius: pxToRem(20);
  			background-color: #002167;
  			font-weight: bold;
			line-height: normal;
			color: #ffffff;
			p{
				margin: 0;
			}
	  	}
	}
}
#progress-bar.complete {
	#myBar {
		width: 100% !important;
		&:before {
			right: pxToRem(14);
		}
		#bar-indicator {
			right: pxToRem(-13);
		}
	}
}

.decision-form {
	position: relative;
	margin-bottom: pxToRem(80) !important;
	counter-reset: section;
	&__hr {
    	opacity: 0.5;
    	margin-top: pxToRem(50);
    	margin-bottom: pxToRem(20);
    	display: none;
    	border: none;
    	border-top: pxToRem(1) solid #d7d6d6;
	}
	&__submit {
		display: none;
		border: none;
	    font-size: pxToRem(20);
	    font-weight: 700;
	    -webkit-transition: color 0.4s cubic-bezier(0.77, 0, 0.175, 1);
	    -moz-transition: color 0.4s cubic-bezier(0.77, 0, 0.175, 1);
	    transition: color 0.4s cubic-bezier(0.77, 0, 0.175, 1);
	    color: #fa612a;
	    line-height: 1.25;
	    right: 0;
	    background: white;
	    margin-bottom: pxToRem(60);
	    position: absolute;
	    &:hover {
	    	transform: translateX(5px);
	    	transition: transform 0.3s;
	    }
	    .c-icon {
	    	font-size: pxToRem(24);
    		margin-left: pxToRem(20);
	    }
	}
}

.decision-question {
	h3:before {
		counter-increment: section;
  		content: counter(section) ". ";
	}
	&__content{
		background-color: #f4f4f4;
		padding: pxToRem(30) pxToRem(20);
		p {
			font-size: pxToRem(16);
			margin-bottom: pxToRem(20);
			margin-top: pxToRem(0);
		}
		hr {
			border: pxToRem(1) solid white;
		}
		.c-btn.c-btn--border-inverse {
			margin: auto;
    		display: block;
    		margin-top: pxToRem(20);
		}
		.question-heading {
    		display: block;
    		margin-top: 0;
    		margin-bottom: pxToRem(20);
    		color: #002664;
			font-size: pxToRem(22);
    		line-height: 1.43;
    		font-weight: 700;
		}
		.question-label {
			&__icon-help {
    			background: url(../mysource_files/help-icon.svg);
    			width: pxToRem(24);
    			height: pxToRem(24);
    			display: inline-block;
  				vertical-align: text-top;
  				margin-top: pxToRem(-5);
  				background-size: 75%;
  				background-repeat: no-repeat;
    			background-position: center;
  				border: none;
    		}
    		&__description {
    			display: none;
    			position: relative;
    			font-size: pxToRem(12);
    			margin-bottom: pxToRem(20);
    			padding-bottom: pxToRem(20);
    			border-bottom: pxToRem(1) solid #dadcde;
    			button {
    				position: absolute;
    				top: pxToRem(-45);
    				right: 0;
    				border: none;
    				background: transparent;
    				color: #002664;
    				font-weight: 800;
    				span {
    					border-radius: 50%;
    					background-color: #002664;
    					color: #f4f4f4;
    					padding: pxToRem(0) pxToRem(4);
    				}
    			}
    			p {
    				font-size: pxToRem(14);
    			}
    			&__link {
					font-weight: 800;
					font-size: pxToRem(14);
					position: relative;
					span {
						transform: rotate(-45deg);
				    	position: absolute;
				    	right: pxToRem(-25);
						.c-icon {
							font-size: pxToRem(18);
    						color: #fa612a;
						}
					}
    			}
    		}
		}
		.radio-label {
		  	display: block;
			position: relative;
			padding-left: pxToRem(30);
			margin-bottom: pxToRem(20);
			cursor: pointer;
			font-size: pxToRem(16);
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			font-weight: 700;
    		color: #002664;
			input {
				position: absolute;
  				opacity: 0;
  				cursor: pointer;
  				width: 0;
		  	}
		  	input:focus + .checkmark {
		  		outline: -webkit-focus-ring-color auto 5px;
		  	}
		  	.checkmark {
			  	position: absolute;
			  	top: pxToRem(0);
			  	left: 0;
			  	height: pxToRem(24);
			  	width: pxToRem(24);
			  	background-color: white;
			  	border-radius: 50%;
			  	border: pxToRem(2) solid #002664;
			  	&:after {
				  	content: "";
				  	position: absolute;
				  	display: none;
				  	top: pxToRem(4);
					left: pxToRem(4);
					width: pxToRem(12);
					height: pxToRem(12);
					border-radius: 50%;
					background: #fa612a;
				}
			}
			&:hover input ~ .checkmark {
			  	//background-color: #ccc;
			}
			input:checked ~ .checkmark {
			  	background-color: white;
			  	border: pxToRem(2) solid #fa612a;
			  	&:after {
			  		display: block;
			  	}
			}
		}
		.radio-bollean {
			display: inline-block;
			margin-right: pxToRem(20);
		}
		.error {
			margin: auto;
		    display: none;
		    width: auto;
		    max-width: 100%;
		    margin-top: 1.25rem;
		    text-align: center;
		    color: red;
		    font-weight: 700;
		}
	}
	&__content.question_number{
		input{
			margin: 0 pxToRem(20) 0 0;
			width: pxToRem(150);
			color: #002664;
			border: none;
			border-radius: 3.125rem;
			font-weight: 700;
    		line-height: 1.5;
    		padding: 0.75rem 1rem 0.625rem;
    		text-align: right;
    		padding-left: pxToRem(35);
    		font-size: pxToRem(18);
		}
		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
		  	-webkit-appearance: none;
		  	margin: 0;
		}
		input[type=number] {
		  	-moz-appearance: textfield;
		}
		.next-question {
			display: inline-block;
		}
		.js-next-question {
			display: inline-block;
		}
		span {
			position: relative;
			display: inline-block;
			&:before {
				content: "$";
				position: absolute;
				top: pxToRem(11);
    			left: pxToRem(20);
    			font-weight: 700;
    			font-size: pxToRem(18);
			}
		}
		button.c-btn.c-btn--border-inverse {
			margin-top: pxToRem(0);
		}
		.error {
			text-align: left;
		}
		&.sans-symbol {
			input {
				padding-left: 1rem;
			}
			span:before {
				content: none;
			}
		}
	}
	&__answer{
		display: none;
		padding: pxToRem(20);
		padding-right: pxToRem(48);
		font-size: pxToRem(16);
		font-weight: 700;
    	color: #002664;
		box-shadow: pxToRem(2) pxToRem(4) pxToRem(20) 0 rgba(0, 0, 0, 0.1);
    	transition: 0.4s ease;
    	position: relative;
    	button {
    		position: absolute;
    		top: pxToRem(35);
    		right: pxToRem(20);
    		color: #6e6e6e;
    		cursor: pointer;
    		border: none;
    		font-weight: 700;
    		background: transparent;
    		.edit-icon {
    			font-size: pxToRem(14);
    			fill: #002664;
			    height: pxToRem(16);
			    overflow: hidden;
			    vertical-align: pxToRem(-3);
			    width: pxToRem(16);
			    margin-left: pxToRem(10);
    		}
    	}
	}
}

.decision-message {
	position: relative;
	margin-top: pxToRem(100);
	&__back {
		border: none;
	    font-size: pxToRem(20);
	    font-weight: 700;
	    -webkit-transition: color 0.4s cubic-bezier(0.77, 0, 0.175, 1);
	    -moz-transition: color 0.4s cubic-bezier(0.77, 0, 0.175, 1);
	    transition: color 0.4s cubic-bezier(0.77, 0, 0.175, 1);
	    color: #fa612a;
	    line-height: 1.25;
	    background: white;
	    margin: 0 0 pxToRem(30) 0;
	    position: absolute;
	    top: pxToRem(-50);
	    &:hover {
	    	transform: translateX(-5px);
	    	transition: transform 0.3s;
	    }
	    .c-icon {
	    	font-size: pxToRem(24);
    		margin-right: pxToRem(20);
	    }
	}
	&__content {
		background-color: #f4f4f4;
		padding: pxToRem(20);
		padding-bottom: pxToRem(10);
		clear: both;
		.apply-now-button {
			position: relative;
	    	padding-right: pxToRem(40);
	    	span{
	    		transform: rotate(-45deg);
	    		position: absolute;
	    		right: pxToRem(10);
	    		top: pxToRem(13);
	    		.c-icon {
	    			font-size: pxToRem(18);
	    			color: #fa612a;
	    		}
	    	}
		}
		p {
			margin: pxToRem(20) 0;
		}
		button {
			margin-bottom: pxToRem(10);
		}
		ol:not([class]) {
			list-style-type: decimal;
	    	list-style-position: inside;
	    	padding-left: pxToRem(0);
	    	margin-top: pxToRem(30);
	    	li {
	    		padding-bottom: pxToRem(10);
	    		border-bottom: pxToRem(1) solid white;
	    		&:before {
		    		content: "";
		    	}
	    		span {
	    			display: block;
	    		}
	    		span + span {
	    			color: #002664;
	    			font-weight: 700;
	    		}
	    	}
	    	li:last-child {
	    		border: none;
	    	}
		}
	}
}

@media print {
	#progress-bar,
	.message__back,
	.decision-form button,
	.decision-form .error,
	.decision-form .loading-container,
	.decision-message__content button {
		display: none;
	}
	.decision-message__content li span {
		margin-left: 1rem;
	}
}

// Loading animation
.loading-container {
	display: none;
	margin: pxToRem(30) auto;
	text-align: center;

	&.is-active {
		display: block;
	}
}
.sk-chase {
	display: inline-block;
	width: 40px;
	height: 40px;
	position: relative;
	animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
	content: '';
	display: block;
	width: 25%;
	height: 25%;
	background-color: #002664;
	border-radius: 100%;
	animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
	100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
	80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
	50% {
		transform: scale(0.4);
	} 100%, 0% {
		transform: scale(1.0);
	}
}