/* Module: S */
.o-long-doc {
    #toc-main-heading {
        padding-left: 3.875rem;
        top: pxToRem(25);
    }
    #toc-list {
        overflow-y: scroll;
        top: pxToRem(50);
        width: pxToRem(320);
        padding-right: pxToRem(25);
        margin-left: pxToRem(-25);
    }
    .current {
        font-weight: bold;
        text-decoration: underline;
        color: $navy-blue;
    }
    #btn-show-hde-toc {
        cursor: pointer;
    }
    #btn-show-hde-toc img {
        width: 20px;
        height: 20px;
        position: fixed;
    }
}
