/* Module: S */
$intention-delay: .3s;

.p-main-nav {
  background-color: $sea-shell;
  position: relative;
  z-index: 2; // to beat elements with position: relative in #main
}

.p-main-nav__mobile-trigger {
  display: none;
}

.p-main-nav__search {
  background-color: transparent;
  display: block;
  padding: 0;
  position: absolute;
  right: 0;
  top: calc(-100% + 1.5rem);
  width: pxToRem(274);
}

.p-main-nav__list-wrap {
  @include sq-transform(translateX(0));
  background-color: inherit;
  overflow: visible;
  position: static;
  visibility: visible;
  width: 100%;
}

.p-main-nav__list {
  @include sq-flex-box();
  @include sq-justify-content(space-between);
}

.p-main-nav__svg {
  display: none;
}

.p-main-nav__item {
  border-top: none;
  margin: 0 0 0 pxToRem(40);

  &:first-of-type {
    margin-left: 0;
  }
}

.p-main-nav__link {
  @include sq-font-size(.9375);
  background-color: inherit;
  margin: pxToRem(25) 0 pxToRem(30);
  padding: 0;
  position: static;

  &:hover {
    color: $dim-gray;
  }
}

.p-main-nav__link-back {
  display: none;
}

.p-main-nav__text {
  &:hover {
    text-decoration: none;
  }
}

.p-main-nav__sub-list {
  @include sq-transition(opacity $fast-transition, visibility $fast-transition);
  left: 0;
  top: 100%;

  &::before {
    background-color: $primary-color;
    content: '';
    display: block;
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: absolute;
    right: 50%;
    top: 0;
    width: 100vw;
  }

  .p-main-nav__item:hover & {
    @include sq-transition(opacity $default-transition $intention-delay, visibility $default-transition $intention-delay);
    opacity: 1;
    visibility: visible;
  }

  .p-card__list {
    @include flex-wrap(wrap);
    @include sq-justify-content(flex-start);
  }
}

.p-main-nav__sub-link {
  max-width: 25%;
  padding-bottom: pxToRem(50);
  padding-right: pxToRem(19);

  &.p-card__item {
    flex-basis: 25%;
    margin: 0;
  }

  &.p-card__item .p-card__title {
    @include sq-font-size(1.125);
    line-height: 1.11;
  }

  .p-card__content {
    @include sq-font-size(.875);
    display: block;
    padding: 0;
  }

  &.p-card__item .e-link--more-svg-icon {
    bottom: auto;
    left: pxToRem(20);
    position: absolute;
    right: auto;
    top: auto;
  }
}
