// Home page card;
.p-card--home {
    position: relative;
    margin-top: pxToRem(-56);

    .o-container {
        @media #{$mq-large} {
            max-width: none;
            width: 95.5%;
        }
    }

    @media #{$mq-medium} {
        margin-bottom: pxToRem(29);
    }

    @media #{$mq-large} {
        margin-bottom: pxToRem(69.2);
    }
}

// Landing page card;
.p-card--landing {
    width: 100%;
    margin: pxToRem(61) auto pxToRem(60) auto;

    @media #{$mq-medium} {
        margin: pxToRem(68) auto pxToRem(78) auto;
    }

    .p-card__item {
        @media #{$mq-large} {
            // margin: pxToRem(10) pxToRem(6);]
            padding: pxToRem(54) pxToRem(25) pxToRem(22) pxToRem(25);
            margin: pxToRem(10) pxToRem(5);
            max-width: pxToRem(380);
            min-height: pxToRem(280);
            @include flex-grow(0);
            @include flex-shrink(1);
            // @include sq-flex-basis(calc(32.5% - 10px));
            @include sq-flex-basis(calc(33% - 5px));

            &:nth-child(3n + 1) {
                margin-left: 0;
            }
            &:nth-child(3n + 3) {
                margin-right: 0;
            }
        }

        .p-card__content {
            font-weight: 400;
            font-size: pxToRem(16);
            line-height: 1.62;
            letter-spacing: 0;
            word-break: break-word;
        }
    }

    .p-card__thumbnail {
        overflow: hidden;
        margin: pxToRem(-38) pxToRem(-18) pxToRem(27) pxToRem(-18);

        @media #{$mq-medium} {
            margin: pxToRem(-38) pxToRem(-18) pxToRem(27) pxToRem(-18);
            max-width: inherit;
            height: pxToRem(248);
        }

        @media #{$mq-large} {
            max-width: 380px;
            margin: pxToRem(-54) pxToRem(-25) pxToRem(27) pxToRem(-25);
            //
        }

        img {
            width: 100%;
        }
    }
}

// card list style;
.p-card__list {
    @media #{$mq-medium} {
        @include sq-flex-box();
        @include sq-flex-direction(row);
        @include sq-justify-content(center);
        @include flex-wrap(wrap);
    }
    @media #{$mq-large} {
        @include flex-wrap(nowrap);
    }

    //landing page card list style;
    .p-card--landing & {
        width: 100%;
        @media #{$mq-medium} {
            // margin: 0 pxToRem(-10);
            @include flex-wrap(wrap);
            @include sq-justify-content(center);
        }
        @media #{$mq-large} {
            @include sq-justify-content(flex-start);
        }
    }
}

/********************
  card with icon style
********************/

.p-card {
    &__item {
        display: block;
        background: #fff;
        padding: pxToRem(38) pxToRem(18) pxToRem(20);
        margin-bottom: pxToRem(20);
        -webkit-box-shadow: 2px 4px 20px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 2px 4px 20px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 2px 4px 20px 0 rgba(0, 0, 0, 0.1);
        transition: 0.4s ease;

        position: relative;

        @media #{$mq-medium} {
            width: calc(48% - 10px);
            margin: pxToRem(10);
            min-height: pxToRem(246);
        }

        @media #{$mq-large} {
            margin: 0 pxToRem(10);
            padding: pxToRem(38) pxToRem(40) pxToRem(30) pxToRem(40);
            @include sq-flex-basis(0);
            @include flex-grow(1);
        }
        &:hover,
        &:focus {
            text-decoration: none;
            .p-card__title,
            .p-card__content {
                color: #fff;
            }

            .e-link--more-svg-icon {
                transform: translateX(5px);
            }
            .p-card__icon .c-icon {
                color: $white;
            }
            background: #002664;
            color: #fff;
            cursor: pointer;
        }

        .e-link--more-svg-icon {
            position: absolute;

            .s-home {
                left: pxToRem(40);
            }
            bottom: pxToRem(25);
            margin-left: 0;
            transition: transform 0.3s;

            @media #{$mq-medium} {
                top: inherit;
            }
            @media #{$mq-large} {
                bottom: pxToRem(35);
                .s-home {
                    left: pxToRem(40);
                }
            }
        }

        .c-icon {
            height: pxToRem(17);
            color: #fa612a;
        }
    }

    &__title {
        font-size: pxToRem(22);
        margin: 0;
        //text-transform: uppercase;

        @media #{$mq-medium} {
            font-size: pxToRem(26);
        }
    }

    &__content {
        color: $black;
        font-weight: 400;
        margin-bottom: pxToRem(20);
        padding-bottom: pxToRem(10);
        @media #{$mq-medium} {
            font-size: pxToRem(18);
        }
    }
}

/* card with header image */
.p-card__image--home {
    @media #{$mq-medium} {
        margin-bottom: pxToRem(60);
    }

    @media #{$mq-large} {
        margin-bottom: pxToRem(76);
    }
}

/* card with icon style */
.p-card__icons {
    .p-card__icon__svg .c-icon {
        color: #002664;
        height: 4rem;
        margin-bottom: pxToRem(36);
    }

    .p-card__icon {
        &--resources .c-icon {
            width: pxToRem(49);
            height: pxToRem(63);
        }

        &--fees .c-icon {
            width: pxToRem(59);
            height: pxToRem(50);
        }

        &--tools .c-icon {
            width: pxToRem(75);
            height: pxToRem(54);
        }

        &--disabilities .c-icon {
            width: pxToRem(56);
            height: pxToRem(74);
        }

        &--aboriginal .c-icon {
            width: pxToRem(75);
            height: pxToRem(68);
        }

        &--contact .c-icon {
            width: pxToRem(62);
            height: pxToRem(62);
        }
        &--trumpet .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--submission .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--progress .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--write .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--people .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--car .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--alert .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--handshake .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--house .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--strate .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--money .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--handtools .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
        &--license .c-icon {
            width: pxToRem(70);
            height: pxToRem(70);
        }
    }

    .p-card__title {
        font-size: pxToRem(22);
        line-height: 0.91;
        letter-spacing: 0;
        text-transform: none;
    }

    .p-card__content {
        font-size: pxToRem(16);
        line-height: 1.62;
        letter-spacing: 0;
    }
}

/* card with image style */
.p-card__image {
    .p-card__list {
        @media #{$mq-medium} {
            @include sq-justify-content(flex-start);
        }
    }

    .p-card {
        &__item {
            padding: pxToRem(0) pxToRem(0) pxToRem(20) pxToRem(0);
            @media #{$mq-large} {
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__item>.e-link--more-svg-icon {
            display: none;
            @media #{$mq-large} {
                display: block;
            }
        }

        &__title {
            font-weight: 500;
            font-size: pxToRem(18);
            margin-bottom: pxToRem(16);
            text-transform: unset;
        }

        &__inner {
            padding: pxToRem(23) pxToRem(20) pxToRem(23);
        }

        &__img {
            img {
                width: 100%;
            }
        }

        &__content {
            p {
                margin: 0;
                font-weight: 400;
                font-size: pxToRem(14);
            }
        }

        .e-link--more-svg-icon {
            bottom: pxToRem(20);
            left: pxToRem(20);
        }

        @media #{$mq-large} {
            &__item .e-link--more-svg-icon {
                left: pxToRem(25);
            }

            &__inner {
                padding: pxToRem(23) pxToRem(25) pxToRem(35);
            }
        }
    }
}

/*
.s-home .p-card__list .p-card__item > .e-link--more-svg-icon {
    display: none;
    @media #{$mq-large} {
        display: block;
    }
}

.inside .p-card__list .p-card__item > .e-link--more-svg-icon {
    display: none;
    @media #{$mq-large} {
        display: block;
    }
}*/
