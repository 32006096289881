/*
 * #page-wrapper's class for layout
 */

// Page layout
.s-float-sidebar {
  .o-aside {
    margin-top: pxToRem(-110);
  }
}

.s-sidebar-bottom-mobile {
  .o-layout--right-aside {
    @include sq-flex-direction(row-reverse);
  }
}

.s-sidebar-split-mobile {
  .o-content {
    .c-side-nav {
      display: none;
    }
  }
  .o-aside {
    .c-side-nav {
      display: block;
    }
  }

  .o-layout--right-aside {
    padding-bottom: pxToRem(60);
  }

  .main > .p-card__container {
    margin-bottom: pxToRem(80);
  }
}


/*
 * #page-wrapper's class for pages
 */

// search page
.s-search {
  .c-result-summary {
    padding: pxToRem(38) 0 pxToRem(11);
  }
  .o-content .c-search {
    margin-top: 0;
  }
}

// news page
.s-news {
  .o-content .c-search {
    margin-top: 0;
  }

  .c-result-summary {
    border-bottom: pxToRem(1) solid $gainsboro;
    padding-top: pxToRem(43);
  }

  .c-news__item {
    padding-top: pxToRem(19);
  }
}


/*
 * Page patterns
 */

// Header
.p-header__inner {
  height: auto;
}

.p-header__logo-img {
  height: pxToRem(87);
  width: pxToRem(196);
}

.p-header__logo {
  padding: pxToRem(35) 0 pxToRem(37.5);
}

/*
 * Element
 */
.e-tags {
  margin: pxToRem(22) 0;
}


/*
 * Utilities
 */
.u-mobile-only {
  display: none;
}

//Fix for the main navigation on Desktop pushing out the page
#page-wrapper {
    overflow-x: hidden;
}


