/* Module: S */
body {
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
}

h1,
.e-h1,
h2,
.e-h2,
h3,
.e-h3,
h4,
.e-h4,
h5,
.e-h5,
h6,
.e-h6 {
  font-weight: $font-regular;
  margin: pxToRem(30) 0 pxToRem(14);
}

h1,
.e-h1 {
  @include sq-font-size(3.625); // 58px
}

h2,
.e-h2 {
  @include sq-font-size(2.8125); // 45px
}

h3,
.e-h3 {
  @include sq-font-size(2); // 32px
}

h4,
.e-h4 {
  @include sq-font-size(1.375); // 22px
}

h5,
.e-h5 {
  @include sq-font-size(1); // 16px
}

h6,
.e-h6 {
  @include sq-font-size(.875); // 14px
}

p {
  margin: pxToRem(14) 0;
}

blockquote {
  margin: pxToRem(30) 0 pxToRem(30) pxToRem(40);
  padding-left: pxToRem(28);

  &::before {
    background-color: $primary-color;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: pxToRem(8);
  }
}

strong {
  font-weight: $font-semi-bold;
}

.highlight {
  background-color: $primary-color;
  color: $white;
}
