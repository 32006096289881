.p-top-links {
  .p-header__box & {
    display: none;
  }
}

.p-top-links__list {
  list-style: none;
  margin: 0;
  padding: 0;


}

.p-top-links__item {
  border-top: pxToRem(1) solid rgba($mercury, .4);

  &:first-of-type {
    border-top: none;
  }
}

.p-top-links__link {
  font-weight: 500;
  @include sq-font-size(.875);
  @include sq-transition(color $default-transition);
  background-color: $white;
  color: $thunder;
  display: block;
  line-height: 1.57;
  padding: pxToRem(21) pxToRem(30) pxToRem(18) pxToRem(20);
  position: relative;

  &:hover {
    color: $secondary-color;
    text-decoration: none;
  }

  &.p-main-nav__link-back {
    padding-left: pxToRem(50);
    position: absolute;
  }
}

.p-top-links__svg {
  color: $mountain-mist;
}

.p-header__box {
  .p-top-links {
    float: right;
    width: calc(100% - 6.625rem);
  }

  .p-top-links__list {
    @include sq-flex-box();
    @include sq-justify-content(flex-end);
    @include flex-wrap(wrap);
  }

  .p-top-links__item {
    border: none;
    margin-left: pxToRem(23);
  }

  .p-top-links__link {
    display: inline;
    line-height: 1;
    padding: 0;
  }
}
