.c-video__cover {
  @include sq-flex-box();
  @include sq-justify-content(center);
  @include sq-flex-direction(column);
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  visibility: visible;
}

.c-video__caption {
  @include sq-flex-basis(auto);
  @include sq-font-size(.875);
  background-color: $primary-color;
  color: $white;
  line-height: 1.86;
  padding: 0 pxToRem(23);
}

.c-video__play-btn {
  @include sq-flex-box();
  @include sq-flex-basis(100%);
  @include sq-flex-direction(column-reverse);
  @include sq-justify-content(center);
  background-position: center;
  background-size: cover;
  border: none;
  color: $white;
  align-items: center;
  position: relative;

  &::before {
    @include sq-transition(background-color $default-transition);
    background-color: rgba($black, .4);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  &:hover::before {
    background-color: rgba($black, .2);
  }
}

.c-video__text {
  font-weight: 700;
  @include sq-font-size(1.125);
}

.c-video__text,
.c-video__play-btn-svg {
  position: relative;
}

.js-video-played {
  .c-video__text,
  .c-video__play-btn-svg {
    display: none;
  }

  &.c-video__cover {
    @include sq-transition(opacity $slow-transition, visibility 0s ease .8s);
    opacity: 0;
    visibility: hidden;
  }
}


