.c-news__container {
    @media #{$mq-large} {
        @include sq-flex-basis(90%);
    }
}

.c-news__inner {
    // home page news inner width;
    .s-home & {
        @media #{$mq-large} {
            width: 66%;
        }
    }
}

.c-news__list {
    // home page news list style
    .s-home & {
        padding: pxToRem(9) pxToRem(20);
        @media #{$mq-medium} {
            padding: pxToRem(9) pxToRem(40);
        }
    }
}

/***  news item itself style ***/

.c-news {
    &__item {
        border-bottom: 1px solid $gainsboro;
        margin-bottom: pxToRem(15);
        &:last-child {
            @media #{$mq-large} {
                border-bottom: none;
            }
        }
    }
    &__category {
        margin-top: pxToRem(12);
    }
    &__title {
        margin-top: pxToRem(20);
        margin-bottom: pxToRem(5);
        font-size: pxToRem(18);
        line-height: pxToRem(22);
    }
    &__date {
        display: inline-block;
        font-size: pxToRem(14); //margin-bottom: pxToRem(12);
    }
    &__content {
        font-size: pxToRem(16);
        p {
            word-wrap: break-word;
        }
    }
    &__button-more {
        display: inline-block;
        margin: pxToRem(9) 0 pxToRem(19) 0
    }
    &__button-more.e-link {
        font-size: pxToRem(20);
    }
    &__more {
        text-align: center;
        margin: pxToRem(20) 0 pxToRem(12) 0;
        .c-btn {
            background: $white;
        }
        @media #{$mq-medium} {
            margin: pxToRem(20) 0 pxToRem(35) 0;
        }
    }
}
