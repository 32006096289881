.p-lists--home {
  @media #{$mq-large} {  
    @include sq-flex-box();
    margin-bottom: pxToRem(73);
    border-bottom: 1px solid rgba(215, 214, 214, .5);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  }
}

.p-links__list {
  
  // home page quick links 
  .s-home & {
    padding: pxToRem(9) pxToRem(20);

    @media #{$mq-medium} {  
      padding: pxToRem(9) pxToRem(40);
    }

    @media #{$mq-large} {
      max-width: pxToRem(408);
      float: right;
      padding: pxToRem(9) pxToRem(16);
    }
  }
}


.p-links {

  &__header >h3 {
    color: #6e6e6e;
    margin-bottom: pxToRem(9);
  }
  
  &__item-detail {
    display:  inline-block;
    width: 73%;
    vertical-align:  middle;
    margin: 0 pxToRem(8) pxToRem(5) pxToRem(15);

    @media #{$mq-small-extra} {
      width: 68%;
    }

    @media #{$mq-medium} {
      width: 85%;
      max-width: inherit;
      margin-bottom: pxToRem(25);
    }

    @media #{$mq-large} {
      width: 75%;
    }
  }

  &__container {
    background: #f4f4f4;
    @media #{$mq-large} {  
      @include sq-flex-basis(60.4%);
    }
  }
}

.p-links {

  &__item {
    display: block;
    border-bottom: 1px solid #c7c7c7;
    position: relative;

    &:hover {
      text-decoration: none;
      .e-link--more-svg-icon {
        transform: translateX(5px);
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .e-link--more-svg-icon {
      position: absolute;
      top: 43%;
      transition: transform .3s;
    }

    .c-icon {
      display: inline-block;
      height: pxToRem(17);
      color: #fa612a;
      vertical-align: text-top;
    }
  }

  &__title > h5{
    color: #002664;
    margin-bottom: 0;
    @media #{$mq-medium} {
      font-size: pxToRem(18);
    }
  }

  &__content p{
    margin-top: 0;
    color: #4a4a4a;
  }

}

// icons 
.p-links__icon {
  
  width: pxToRem(29);
  height: pxToRem(29);
  display: inline-block;
  vertical-align: text-top;
  margin-top: pxToRem(-10);

  &-form {
    background: url(../mysource_files/form-icon.svg);
  }

  &-consumer {
    background: url(../mysource_files/consumer-icon.svg);
    height: pxToRem(24);
  }

  &-scam {
    background: url(../mysource_files/scam-icon.svg);
    height: pxToRem(35);
  }

  &-bond {
    background: url(../mysource_files/bond-icon.svg);
    background-repeat:no-repeat;
    background-position: right;
  }

  &-home {
    background: url(../mysource_files/home-icon.svg);
    background-repeat:no-repeat;
  }

  &-online {
    background: url(../mysource_files/online-icon.svg);
    background-repeat:no-repeat;
  }

  &-help {
    background: url(../mysource_files/help-icon.svg);
    width: pxToRem(28);
    height: pxToRem(28);
  }
}

