/* Module: S */
// reset icon; this is recommended properties
.c-icon {
  fill: currentColor;
  font-size: $icon-default-size;
  height: 1em;
  overflow: hidden;
  vertical-align: -.15em;
  width: 1em;
}

.c-icon--primary {
  color: $primary-color;
}

.c-icon--secondary {
  color: $secondary-color;
}

.c-icon--tertiary {
  color: $tertiary-color;
}

.c-icon--small {
  font-size: $icon-small-size;
}

.c-icon--large {
  font-size: $icon-large-size;
}
