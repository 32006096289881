.o-col__item {
  .o-col--2-not-responsive & {
    @include span(6);

    &:nth-child(2n + 2) {
      @include span(6 last);
    }
  }
}

.o-layout--right-aside {
  padding: pxToRem(30) 0;

  .o-aside,
  .o-content {
    @include susy-clearfix;
    width: 100%;
  }
}
