
/*
--------------------
Modules
--------------------
*/

.p-header__logo {
  padding: pxToRem(17) 0 0 pxToRem(80);
}

.p-footer__inner {
  @include sq-flex-box();
}
