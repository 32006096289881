.c-results__list {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.c-results__tags {
    margin: pxToRem(9) 0 pxToRem(18);
}

.c-results__item {
    border-bottom: pxToRem(1) solid $gainsboro;
    padding: pxToRem(26) 0 pxToRem(30);
    &:first-of-type {
        border-top: pxToRem(1) solid $gainsboro;
    }
}

.c-results__title {
    line-height: 1.14;
    margin: pxToRem(20) 0;
}

.c-results__description {
    color: $armadillo;
    line-height: 1.63;
    margin: pxToRem(25) 0 pxToRem(10);
    p {
        word-wrap: break-word;
    }
}

.c-results__cta {
    margin: pxToRem(10) 0;
    .e-link {
        @include sq-font-size(1.25);
        line-height: 1;
    }
}

.c-results {
    .c-breadcrumbs {
        @include sq-font-size(.875);
        background-color: inherit;
        line-height: 1.43;
    }
    .c-breadcrumbs__link, .c-breadcrumbs__svg {
        color: $primary-color;
        display: inline-block;
    }
    .c-breadcrumbs__link {
        font-weight: 500;
    }
    .c-breadcrumbs__svg {
        .c-icon {
            fill: $primary-color;
        }
    }
}

.c-results__tools {
    @include sq-flex-box();
    @include sq-justify-content(flex-end);
    margin: pxToRem(30) 0 pxToRem(-30);
}

.c-results__tools-list {
    @include sq-flex-box();
    @include sq-flex-basis(100%);
    background-color: $alabaster;
    list-style: none;
    padding: pxToRem(19) 0;
}

.c-results__tools-item {
    @include sq-flex-basis(50%);
    border-left: pxToRem(1) solid rgba($mercury, .4);
    padding: 0 pxToRem(25);
    text-align: center;
    &:first-of-type {
        border-left: none;
    }
}

.c-results__tools-link {
    font-weight: 700;
    @include sq-font-size(1);
    @include sq-transition(color $default-transition);
    line-height: 1.25;
    &:hover {
        color: $secondary-color;
        text-decoration: none;
    }
    &.c-tools__link--copy {
        background-color: inherit;
        color: $sapphire;
        border: none;
        padding: 0;
    }
    &.c-tools__link--copy:hover {
        color: $secondary-color;
    }
}
