.c-tools {
  list-style: none;
  margin: pxToRem(55) 0;
}

.c-tools__list {
  background-color: $alabaster;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;

  .c-tools__body & {
    background-color: $white;
  }
}

.c-tools__item {
  padding: 0 pxToRem(15) 0 pxToRem(20);
}

.c-tools__link {
  font-weight: 700;
  @include sq-font-size(1);
  @include sq-transition(color $default-transition);
  border-top: pxToRem(1) solid rgba($mercury, .4);
  color: $primary-color;
  display: block;
  line-height: 1.25;
  padding: pxToRem(22) 0 pxToRem(23) pxToRem(40);
  position: relative;

  .c-tools__item:first-of-type > & {
    border-top: none;
  }

  .c-tools__item.is-active > & {
    color: $tertiary-color;
  }

  &:focus {
    color: $dove-gray;
  }

  &:hover {
    color: $dove-gray;
    text-decoration: none;
  }

  .c-tools__item.is-selected > & {
    color: $dove-gray;
  }
}

.c-tools__svg {
  left: 0;
  position: absolute;
  top: pxToRem(22);

  .c-icon {
    @include sq-font-size(1);
    fill: $tertiary-color;

    .c-tools__body & {
      fill: currentColor;
    }
  }
}

.c-tools__link--copy {
  position: relative;

  &::after {
    @include sq-transition(opacity $fast-transition, visibility $fast-transition);
    content: '';
    display: inline-block;
    left: calc(100% + .25rem);
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
  }

  &.u-copied {
    color: $pigment-green;

    &::after {
      content: '\2714';
      opacity: 1;
      visibility: visible;
    }
  }

  &.u-error {
    color: $crayon-red;

    &::after {
      content: '\2716';
      opacity: 1;
      visibility: visible;
    }
  }
}


// tool item with drop-down
.c-tools {
  .js-dropdown {
    position: relative;
  }

  .js-dropdown-trigger::after {
    @include sq-transition(visibility $fast-transition .3s);
    border-bottom: pxToRem(12) solid $white;
    border-left: pxToRem(14) solid transparent;
    border-right: pxToRem(14) solid transparent;
    content: '';
    height: 0;
    left: pxToRem(-8);
    position: absolute;
    top: calc(100% - .6rem);
    width: 0;
    visibility: hidden;
    z-index: 1;
  }

  .js-dropdown-body {
    @include sq-transition(max-height $default-transition, box-shadow $fast-transition .4s);
    left: 0;
    position: absolute;
    width: 100%;
  }

  .is-active {
    .js-dropdown-body {
      @include sq-transition(max-height $default-transition, box-shadow $fast-transition);
      box-shadow: 0 0 pxToRem(5) pxToRem(3) $near-white;
    }

    .js-dropdown-trigger::after {
      @include sq-transition(visibility $default-transition);
      visibility: visible;
    }
  }
}
