.c-social-links {
  margin: pxToRem(35) 0;
}

.c-social-links__link {
  color: $alabaster;
  margin-left: pxToRem(16);

  &:first-of-type {
    margin-left: 0;
  }

  .c-icon {
    @include sq-transition(fill $default-transition);
  }

  &:hover {
    .c-icon {
      fill: $secondary-color;
    }
  }
}

.c-social-links__svg {
  .c-icon {
    @include sq-font-size(1.375);
  }
}
