/* Module: S */
.decision-form {
    margin-bottom: 0;
}
.decision-question {
    &__content{
        .question-heading {
            font-size: pxToRem(28);
        }
        .question-label {
            &__description {
                button {
                    &:before {
                        content: "Close";
                        margin-right: pxToRem(10);
                    }
                }
            }
        }
    }
    &__answer{
        padding-right: pxToRem(100);
        button {
            &:before{
                content: "Edit";
            }
        }
    }
}

.decision-message {
    &__content {
        ol:not([class]) {
            list-style-type: none;
            li {
                padding-left: pxToRem(20);
                overflow: hidden;
                &:before {
                    content: counter(orderlist) ". ";
                    left: 0px;
                    top: 0px;
                    font-weight: 400;
                    color: #4a4a4a;
                }
                span {
                    display: block;
                    float: left;
                    width: 50%;
                }
                span + span {
                    padding-left: 2%;
                }
            }
        }
    }
}