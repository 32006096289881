/* Module: S */
table {
  min-width: $default-table-min-width;
}

.e-tbl__wrapper {
  position: relative;

  &::after {
    @include sq-transition(opacity $table-transition, visibility $table-transition);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    content: '';
    display: block;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1);
    height: 100%;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    visibility: visible;
    width: 30%;
  }

  &.is-end {
    &::after {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.e-tbl__caption {
  text-align: center;
}

.e-tbl__inner {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  position: relative;

  & > table {
    margin: 0 0 pxToRem(16);  // room for scrollbar
  }
}

.no-tbl-scrollable {
  min-width: inherit;
}