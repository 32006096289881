// Colours
// Black
$pure-black: #000;
$black: #393939;
$almost-black: #0c1921;
$black-12: rgba(0, 0, 0, .12);

// Blue
$azure: #009ee0;
$azure-two: #09a2e2;
$azure-three: #03a0e1;
$baby-blue: #a8e4ff;
$dark-sky-blue: #1ea3e1;
$dark-sky-blue-two: #3ba6de;
$dark-sky-blue-three: #2aa4e0;
$light-blue: #7ed5f9;
$marine: #02345b;
$marine-blue: #00335c;
$marine-blue-two: #00345d;
$navy-blue: #001b50;
$light-navy-blue: #939eb4;
$ugly-blue: #32657c;
$blue-67: rgba(80, 206, 249, 0.67);

// Green
$nasty-green: #78a243;
$turtle-green: #354422;
$green-67: rgba(211, 249, 80, 0.67);

// Grey
$boulder: #7b7b7b;
$brownish-grey: #666;
$brownish-grey-two: #606060;
$greyish: #a7a7a7;
$greyish-brown: #494949;
$warm-grey: #7a7a7a;

// Orange
$orange-67: rgba(249, 159, 80, 0.67);

// Pink
$hot-pink: #f10082;
$strong-pink: #f20e89;

// Red
$boston-university-red: #c00;
$scarlet: #d10003;

// White
$white-pure: #fff;
$white: #f4f4f4;
$white-two: #e4e4e4;
$white-three: #fff;
$white-five: #d8d8d8;

// Yellow
$fluorescent-orange: #ffbe00;
$marigold: #ffbc00;

// Branding
$primary-color: $navy-blue;
$secondary-color: $marigold;
$tertiary-color: $greyish;

$bg-colors: (
  // black
  (pure-black, $pure-black),
  (black, $black),
  (almost-black, $black),
  (black-12, $black-12),

  // blue
  (azure, $azure),
  (azure-two, $azure-two),
  (azure-three, $azure-three),
  (baby-blue, $baby-blue),
  (marine-blue, $marine-blue),
  (dark-sky-blue, $dark-sky-blue),
  (dark-sky-blue-two, $dark-sky-blue-two),
  (dark-sky-blue-three, $dark-sky-blue-three),
  (light-blue, $light-blue),
  (marine, $marine),
  (marine-blue, $marine-blue),
  (marine-blue-two, $marine-blue-two),
  (navy-blue, $navy-blue),
  (ugly-blue, $ugly-blue),
  (blue-67, $blue-67),

  // green
  (nasty-green, $nasty-green),
  (turtle-green, $turtle-green),
  (green-67, $green-67),

  // grey
  (boulder, $boulder),
  (brownish-grey, $brownish-grey),
  (brownish-grey-two, $brownish-grey-two),
  (greyish, $greyish),
  (greyish-brown, $greyish-brown),
  (warm-grey, $warm-grey),

  // orange
  (orange-67, $orange-67),

  // pink
  (hot-pink, $hot-pink),
  (strong-pink, $strong-pink),

  // red
  (boston-university-red, $boston-university-red),
  (scarlet, $scarlet),

  // white
  (white-pure, $white-pure),
  (white, $white),
  (white-two, $white-two),
  (white-three, $white-three),
  (white-five, $white-five),

  // yellow
  (fluorescent-orange, $fluorescent-orange),
  (marigold, $marigold),

  // user friendly themes
  (blue, $azure),
  (green, $nasty-green),
  (light-grey, $white),
  (navy, $marine-blue-two),
  (orange, $orange-67),
  (pink, $strong-pink),
  (red, $scarlet),
  (yellow, $marigold),

  // branding
  (primary-color,$primary-color),
  (secondary-color,$secondary-color),
  (tertiary-color,$tertiary-color)
);
