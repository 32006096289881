#sq-google-translate-element {
    background-color: $white;
    border-top: pxToRem(1) solid rgba($near-white, 0.4);
    padding: pxToRem(37) pxToRem(20);
    .goog-te-gadget, .goog-logo-link {
        @include sq-font-size(0.625);
    }
    .goog-te-gadget {
        >div {
            border: pxToRem(1.6) solid $near-white;
            margin-bottom: pxToRem(6);
            padding: pxToRem(6) pxToRem(11);
            width: 55%;
        }
    }
    .goog-te-combo {
        @include sq-font-size(0.75);
        background-color: inherit;
        border: none;
        color: $thunder;
        margin: 0;
        width: 100%;
        @media screen and (max-width: 60em) {
            background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
            background-position: calc(100% - 7px) calc(1em - 5px), calc(100% - 3px) calc(1em - 5px);
            background-size: 5px 5px, 5px 5px, 1px 1.5em;
            background-repeat: no-repeat;
        }
    }
    .goog-logo-link {
        img {
            display: inline-block;
            height: pxToRem(19);
            margin: 0;
            width: auto;
        }
    }
    .google-translate__svg-down-arrow {
        width: 15px;
        position: relative;
        top: 2px;
        right: 16px;
    }
}
