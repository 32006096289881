.o-long-doc {
    @media (max-width: 60em) {
        .toc {
            order: 1;
        }
        #btn-show-hde-toc {
            display: none;
        }
    }
}
