/* Module: S */
.ui-autocomplete {
  padding: 0 pxToRem(20);
}

.ui-menu-item {
  padding: pxToRem(18) 0 pxToRem(16);
}

.ui-menu-item-wrapper {
  @include sq-font-size(1);
}
