.c-breadcrumbs {
  background-color: $primary-color;
  list-style: none;
  padding: pxToRem(14) 0 pxToRem(10);
  position: relative;
}

.c-breadcrumbs__link {
  @include sq-font-size(.875);
  color: $white;
  display: none;
  line-height: 1.43;
  padding-right: pxToRem(16);
  position: relative;

  &:nth-last-of-type(2) {
    display: inline-block;
  }

  .is-top-level & {
    display: inline-block;
  }
}
.c-breadcrumbs__svg,
.c-breadcrumbs__svg-mobile {
  position: absolute;

  .c-icon {
    @include sq-font-size(.5625);
    fill: $white;
  }
}

.c-breadcrumbs__svg {
  display: none;
  position: absolute;
  right: pxToRem(2);
  top: 0;

  .c-breadcrumbs__link:last-of-type & {
    display: none;
  }
}

.c-breadcrumbs__svg-mobile {
  left: pxToRem(-15);
  top: pxToRem(13);

  .is-top-level & {
    display: none;
  }
}
