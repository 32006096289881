// Blue
$sapphire: #002664;
$ocean-boat-blue: #0076c2;
$navy-blue: #0979bf;

// Green
$pigment-green: #00b246;

// Red
$orange-red: #fa612a;
$crayon-red: #ed0036;

// Grey
$armadillo: #4a4a4a;
$dove-gray: #6e6e6e;
$aluminum: #979797;
$mercury: #c7c7c7;
$sea-shell: #f1f1f1;
$alabaster: #fafafa;
$iron: #d7d6d6;
$wild-sand: #f4f4f4;
$desert-storm: #f8f8f8;
$dim-gray: #6a6a6a;
$white-smoke: #ededed;
$gainsboro: #d8d8d8;
$thunder: #4b4b4b;
$mountain-mist: #949494;

// White
$white: #ffffff;
$near-white: #ececec;

// Black
$black: #000000;

// Branding
$primary-color: $sapphire;
$secondary-color: $ocean-boat-blue;
$tertiary-color: $orange-red;

$custom-bg-colors: (
    (primary-color, $primary-color),
    (secondary-color, $secondary-color),
    (tertiary-color, $tertiary-color)
);

.t-bg {
    @each $theme, $bg-color in $custom-bg-colors {
        &--#{$theme} {
            background-color: $bg-color;
        }
    }
}
