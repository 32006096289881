a {
  font-weight: 500;
  @include sq-font-size(1);
  color: $primary-color;

  &[class="is-active"] {
    text-decoration: underline;
  }

  &.e-link {
    @include sq-font-size(1.375);
    font-weight: 700;
    @include sq-transition(color .3s);
    color: $tertiary-color;

    &.is-active,
    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }

  &.e-link--more-svg {
    color: $sapphire;

    &.is-active,
    &:hover {
      color: $sea-shell;
      &::before {
        content: none;
      }
    }

    .e-link--more-svg-icon {
      right: pxToRem(20);
      top: pxToRem(28);
    }
  }
}

.e-link--more-svg {
  font-weight: 500;
  @include sq-font-size(1);

  background-color: $sea-shell;
  color: $primary-color;
  display: block;
  line-height: 1.25;
  padding: pxToRem(30) pxToRem(45) pxToRem(27) pxToRem(19);
  width: 100%;

  &.is-active,
  &:hover {
    @include sq-transition(color $default-transition, background-color $default-transition);
    background-color: $primary-color;
  }
}

.e-link--more-svg-icon {
  @include sq-transition(transform $default-transition);

  *:hover > & {
    @include sq-transform(translateX(pxToRem(5)));
  }

  .c-icon {
    @include sq-font-size(1.1875);
    color: $tertiary-color;
  }
}

.e-link-tag {
  @include sq-border-radius(pxToRem(5));
  font-weight: 500;
  @include sq-font-size(.875);
  background-color: $white-smoke;
  color: $primary-color;
  display: inline-block;
  line-height: 1.43;
  margin-left: pxToRem(5);
  padding: pxToRem(5) pxToRem(9);

  &:first-of-type {
    margin-left: 0;
  }
}

a.e-link-tag {
  @include sq-transition(color $default-transition);

  &:hover {
    color: $secondary-color;
    text-decoration: none;
  }
}
