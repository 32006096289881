// $container-padding: 1.25rem;
$button-width: 5rem; // 80px
$nav-width: calc(100% - 2.9375rem);
$page-wrap-tranlsate-x: calc(100% - 2.9375rem);

.js-enabled-nav-trigger {
    #page-wrapper {
        @include sq-transition(transform $default-transition);

        &::before {
            content: "";
        }
    }
}

.p-main-nav__mobile-trigger {
    background-color: $wild-sand;
    color: $primary-color;
    height: pxToRem(80);
    left: 0;
    margin: 0;
    padding: pxToRem(19) pxToRem(17);
    position: absolute;
    top: 0;
    width: pxToRem(80);

    &:hover {
        background-color: $wild-sand;
        border-color: transparent;
        color: $secondary-color;
    }
}

.p-main-nav__mobile-svg--active {
    display: none;

    .c-icon {
        @include sq-font-size(1.25);
        margin-top: pxToRem(10);
    }
}

.p-main-nav__list-wrap {
    @include sq-transition(
        transform $default-transition,
        background-color $default-transition,
        visibility $fast-transition 0.4s
    );
    //@include sq-transform(translateX(calc(-100% - 1.25rem)));
    -webkit-transform: translateX(-100%) translateX(-1.25rem);
    -moz-transform: translateX(-100%) translateX(-1.25rem);
    -ms-transform: translateX(-100%) translateX(-1.25rem);
    transform: translateX(-100%) translateX(-1.25rem);
    background-color: $white;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: $nav-width;
}

.p-main-nav__contents {
    @include sq-transition(transform $default-transition);
}

.p-main-nav__list {
    list-style: none;
}

.p-main-nav__list,
.p-main-nav__sub-list {
    margin: 0;
    padding: 0;
}

.p-main-nav__list {
    position: relative;
}

.p-main-nav__sub-list,
.p-main-nav__link-back {
    @include sq-transition(
        opacity $default-transition,
        visibility $default-transition
    );
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 100%;
    width: 100%;

    .is-current > & {
        opacity: 1;
        visibility: visible;
    }
}

.p-main-nav__sub-list {
    top: pxToRem(60);
}

.p-main-nav__link-back {
    color: $dove-gray;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
}

.p-main-nav__item {
    border-top: pxToRem(1) solid rgba($mercury, 0.4);

    &:first-of-type {
        border-top: none;
    }
}

.p-main-nav__link {
    font-weight: 500;
    @include sq-font-size(0.875);
    @include sq-transition(color $default-transition);
    background-color: $desert-storm;
    display: block;
    line-height: 1.57;
    padding: pxToRem(21) pxToRem(30) pxToRem(18) pxToRem(20);
    position: relative;

    &:hover {
        color: $secondary-color;
        text-decoration: none;
    }

    &.p-main-nav__link-back {
        padding-left: pxToRem(50);
        position: absolute;
    }
}

.p-main-nav__text {
    &:hover {
        text-decoration: underline;
    }
}

.p-main-nav__svg {
    position: absolute;
    right: pxToRem(21);
    top: pxToRem(20);

    .c-icon {
        @include sq-font-size(0.5);
    }

    .p-main-nav__link-back & {
        @include sq-transform(rotate(-180deg));
        left: pxToRem(20);
        right: auto;
    }
}

.p-main-nav__search {
    background-color: $white;
    height: pxToRem(80);
    padding: pxToRem(16) pxToRem(14);
}

.p-main-nav__sub-list {
    background-color: $primary-color;
    padding-top: pxToRem(12);
}

.p-main-nav__sub-link {
    background-color: inherit;
    box-shadow: none;
    color: $white;
    line-height: 1.43;
    margin-bottom: 0;
    min-height: 0;
    padding: pxToRem(17) pxToRem(50) pxToRem(18) pxToRem(20);
    position: relative;

    &.p-card__item .p-card__title {
        @include sq-font-size(0.875);
        text-transform: initial;
    }
    .p-card__title {
        color: inherit;
        font-weight: $custom-font-semi-bold;
    }

    .p-card__content {
        color: inherit;
        display: none;
    }

    &.p-card__item .e-link--more-svg-icon {
        bottom: auto;
        left: auto;
        right: pxToRem(23);
        top: pxToRem(16);
    }

    .c-icon {
        @include sq-font-size(1.1875);
    }
}

// Main Nav opening
.is-nav-open {
    overflow: hidden;

    #page-wrapper {
        //@include sq-transform(translateX($page-wrap-tranlsate-x));
        -webkit-transform: translateX(100%) translateX(-2.9375rem);
        -moz-transform: translateX(100%) translateX(-2.9375rem);
        -ms-transform: translateX(100%) translateX(-2.9375rem);
        transform: translateX(100%) translateX(-2.9375rem);

        &::before {
            background-color: $ocean-boat-blue;
            height: 100%;
            opacity: 0.5;
            position: absolute;
            width: 100%;
            z-index: 3;
        }

        .p-main-nav__mobile-trigger {
            z-index: 4;
        }
    }

    .p-main-nav__mobile-trigger {
        background-color: $primary-color;
        color: $white;
    }

    .p-main-nav__list-wrap {
        @include sq-transition(
            transform $default-transition,
            background-color $default-transition
        );
        visibility: visible;
    }

    .p-main-nav__mobile-svg {
        display: none;
    }

    .p-main-nav__mobile-svg--active {
        display: block;
    }

    .p-main-nav__contents {
        height: calc(100vh - 5rem);
        overflow: auto;
    }

    .s-home .p-main-nav__contents {
        height: 100vh;
    }

    .p-main-nav__list {
        overflow: hidden;
    }
}

// Main Nav level 2 opening
.is-showing-level-2 {
    &.p-main-nav__list-wrap {
        background-color: $primary-color;
    }

    .p-main-nav__contents {
        //@include sq-transform(translateX(calc(-100%)));
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    .is-nav-open & .p-main-nav__contents,
    .is-nav-open & .p-main-nav__list {
        overflow: visible;
    }

    .p-main-nav__sub-list {
        height: calc(100vh - 8.75rem);
        overflow: auto;
    }
}
