.p-bottom-links {
  margin: pxToRem(34) 0;
}

.p-bottom-links__list {
  @include sq-flex-box();
  @include sq-flex-direction(column);
  list-style: none;
  margin: 0;
  padding: 0;
}

.p-bottom-links__item {
  margin-top: pxToRem(34);

  &:first-of-type {
    margin-top: 0;
  }
}

.p-bottom-links__link {
  @include sq-font-size(1);
  font-weight: 500;
  @include sq-transition(color $default-transition);
  color: $alabaster;
  line-height: 1;

  &:hover {
    text-decoration: none;
    color: $navy-blue;
  }
}
