/* Module: S */
.o-layout--right-aside {
  @include sq-flex-box();
  @include sq-flex-direction(row-reverse);
  @include flex-wrap(nowrap);
  padding: pxToRem(75) 0;

  .o-aside {
    @include sq-flex-basis(pxToRem(301));
    flex-shrink: 0;
  }

  .o-content {
    @include sq-flex-basis(100%);
    flex-shrink: 1;
    padding-right: pxToRem(74);
    max-width: calc(100% - 18.8125rem);
  }
}
