
.c-chatbot {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: $white;
  display: none;

  &__container {
    position: fixed;
    display: block;
    z-index: 999;
    bottom: 0;
    left: 0;
    right:0;
    width: 100%;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.6), 0 0 40px rgba(0, 0, 0, 0.4) inset;
    -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.6), 0 0 40px rgba(0, 0, 0, 0.4) inset;
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.6), 0 0 40px rgba(0, 0, 0, 0.4) inset;

  // -webkit-box-shadow:0 0 10px rgba(0, 0, 0, 0.3);
  // -moz-box-shadow:0 0 10px rgba(0, 0, 0, 0.3);
  // box-shadow:0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  &__content {
    width: 100%;
    height: pxToRem(500);
    border: none;
  }
}

.c-btn__container {

  .c-news__more {
    margin: 0;
    border-radius:0;
    background:$ocean-boat-blue;
    border: none;
    width: 100%;
    .c-icon {
      font-size: 1rem;
      transform: rotate(180deg);
      @include sq-transition(all 0.2s ease);
    }
  }

  .active .c-icon {
    transform: rotate(0deg);
  }
}