// Placeholders
%default-flex-container {
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  display: flex;
  -ms-flex-align: stretch;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -ms-flex-line-pack: stretch;
  -ms-flex-pack: start;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

%default-flex-item {
  -webkit-align-self: auto;
  align-self: auto;
  -ms-flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  flex: 0 1 auto;
  -ms-flex-item-align: auto;
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
}

// Object
.o-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $default-container-max-width;
  padding-left: $default-container-padding;
  padding-right: $default-container-padding;
  width: 100%;
}

// Layout
.o-col {
  @extend %default-flex-container;
  @include container();
}

.o-col__item {
  @extend %default-flex-item;
  margin-bottom: $default-col-layout-margin-bottom;

  // 1 column layout
  .o-col--1 & {
    width: 100%;
  }

  // 2 columns layout
  .o-col--2 & {
    width: 100%;

    @media #{$mq-medium} {
      @include span(6);
    }

    &:nth-child(2n + 2) {
      @media #{$mq-medium} {
        @include span(6 last);
      }
    }
  }

  // 2 columns layout uneven
  .o-col--2.o-col--left & {
    @media #{$mq-medium} {
      @include span(3);
    }

    &:nth-child(2n + 2) {
      @media #{$mq-medium} {
        @include span(9 last);
      }
    }
  }

  .o-col--2.o-col--right & {
    @media #{$mq-medium} {
      @include span(9);
    }

    &:nth-child(2n + 2) {
      @media #{$mq-medium} {
        @include span(3 last);
      }
    }
  }

  // 3 columns layout
  .o-col--3 & {
    width: 100%;

    @media #{$mq-medium} {
      @include span(4);
    }

    &:nth-child(3n + 3) {
      @media #{$mq-medium} {
        @include span(4 last);
      }
    }
  }

  // 4 columns layout
  .o-col--4 & {
    @include span(6);

    @media #{$mq-medium} {
      @include span(3);
    }

    &:nth-child(2n + 2) {
      @include span(6 last);

      @media #{$mq-medium} {
        @include span(3);
      }
    }

    &:nth-child(4n + 4) {
      @media #{$mq-medium} {
        @include span(3 last);
      }
    }
  }

  .o-col--5 & {
    @include span(6);

    @media #{$mq-medium} {
      @include span(2.4);
    }

    &:nth-child(2n + 2) {
      @include span(6 last);

      @media #{$mq-medium} {
        @include span(2.4);
      }
    }

    &:nth-child(5n + 5) {
      @media #{$mq-medium} {
        @include span(2.4 last);
      }
    }
  }

  // no gutters
  .no-gutters & {
    margin-bottom: 0;
  }

  .o-col--2.no-gutters & {
    margin-left: 0;
    margin-right: 0;

    @media #{$mq-medium} {
      @include span(1 of 2 inside no-gutters);
    }

    &:nth-child(2n + 2) {
      @media #{$mq-medium} {
        @include span(1 of 2 last inside no-gutters);
      }
    }
  }

  .o-col--3.no-gutters & {
    margin-left: 0;
    margin-right: 0;

    @media #{$mq-medium} {
      @include span(1 of 3 inside no-gutters);
    }

    &:nth-child(3n + 3) {
      @media #{$mq-medium} {
        @include span(1 of 3 last inside no-gutters);
      }
    }
  }

  .o-col--4.no-gutters & {
    @include span(1 of 2 inside no-gutters);
    margin-left: 0;
    margin-right: 0;

    @media #{$mq-medium} {
      @include span(1 of 4 inside no-gutters);
    }

    &:nth-child(2n + 2) {
      @include span(1 of 2 inside no-gutters last);

      @media #{$mq-medium} {
        @include span(1 of 4 inside no-gutters);
      }
    }

    &:nth-child(4n + 4) {
      @media #{$mq-medium} {
        @include span(1 of 4 inside no-gutters last);
      }
    }
  }

  .o-col--5.no-gutters & {
    @include span(1 of 2 inside no-gutters);
    margin-left: 0;
    margin-right: 0;

    @media #{$mq-medium} {
      @include span(1 of 5 inside no-gutters);
    }

    &:nth-child(2n + 2) {
      @include span(1 of 2 inside no-gutters last);

      @media #{$mq-medium} {
        @include span(1 of 5 inside no-gutters);
      }
    }

    &:nth-child(5n + 5) {
      @media #{$mq-medium} {
        @include span(1 of 5 inside no-gutters last);
      }
    }
  }
}

// Layout
.o-layout {
  @extend %default-flex-container;
  @include container($susy-layout);
}

// Right sidebar
.o-layout--2col-right {
  .o-content {
    @include span(12 of 16);
    padding-right: pxToRem(20); // gutter is not accurate so need to add more padding here
  }

  .o-aside {
    @include span(4 of 16 last);
  }
}

// Debug - add colour to column items
.o-col--debug {
  .o-col__item {
    background-color: $default-debug-colour;
    color: white;
  }
}
