// General

.c-search__wrap {
  display: inline-block;
  position: relative;
  width: 100%;
}

.c-search__input {
  @include sq-font-size(.875);
  @include sq-border-radius(pxToRem(50));
  box-shadow: 0 0 pxToRem(3) pxToRem(2) $near-white;
  box-sizing: border-box;
  border: none;
  display: block;
  line-height: normal;
  outline: none;
  overflow: hidden;
  padding: pxToRem(17) pxToRem(73) pxToRem(17) pxToRem(20);
  white-space: nowrap;
  width: 100%;
  text-indent: 0;
  text-overflow: ellipsis;

  // this is for hidding placeholder on focus
  &:focus::-webkit-input-placeholder { color:transparent; }
  &:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
  &:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
  &:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

  &:focus {
    box-shadow: 0 0 pxToRem(11) pxToRem(2) $near-white inset;
  }
}

input.c-search__input {
  box-sizing: border-box;
}

.c-search__btn {
  @include border-right-radius(pxToRem(50));
  font-weight: 700;
  @include sq-transition(background-color $default-transition);
  background-color: $tertiary-color;
  border: none;
  color: $white;
  height: 100%;
  min-width: pxToRem(53);
  padding: pxToRem(14) pxToRem(16);
  position: absolute;
  right: pxToRem(-1);
  top: 0;

  &:hover {
    background-color: darken($tertiary-color, 10%);
  }
}

.c-search__btn-text {
  display: none;
}

.c-search__svg,
.c-search__icon {
  .c-icon {
    font-size: pxToRem(20);
  }
}

.c-search__icon {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  .c-icon {
    fill: $armadillo;
  }
}

// Customise

// home
.p-home-search {
  margin: pxToRem(16) 0 pxToRem(37) 0 ;

  .c-search__input {
    box-shadow: none;
    padding: pxToRem(22) pxToRem(73) pxToRem(22) pxToRem(20);
  }

  .c-search__svg {
    display: block;
    margin-left: pxToRem(-5);
    margin-top: pxToRem(4);
  }
}

