/* Module: S */
.slick-dots {
  > * {
    margin: 0 pxToRem(5);
  }

  button {
    height: pxToRem(10);
    width: pxToRem(10);
  }
}
