/* Module: S */
.p-top-links {
  display: none;

  .p-header__box & {
    display: block;
    padding-right: pxToRem(170); // leave the space for google translate
    width: calc(90% - 12.25rem);
  }
}
