/* Module: S */
body {
  @include sq-font-size(1);
  line-height: 1.63;
}

h1,
.e-h1 {
  @include sq-font-size(4); // 64px
  line-height: 1.25;
}

h2,
.e-h2 {
  @include sq-font-size(1.75); // 28px
  line-height: 1.43;
}


.e-intro {
  @include sq-font-size(1.375);
  line-height: 1.45;
}

ol:not([class]), ul:not([class]) {
  padding-left: pxToRem(62);

  ol, ul {
    margin-top: pxToRem(26);

    ol, ul {
      margin-top: pxToRem(14);
    }

    ol {
      padding-left: pxToRem(35);
    }

    ul {
      padding-left: pxToRem(30);
    }
  }

  > li:not([class]) {
    margin-bottom: pxToRem(20);
  }

  ol {
    padding-left: pxToRem(35);
  }

  ul {
    padding-left: pxToRem(31);
  }
}

ul:not([class]) {
  > li:not([class]) {
    &::before {
      left: pxToRem(-25);
    }
  }

  ul {
    > li:not([class]) {
      &::before {
        left: pxToRem(-29);
      }
    }

    ul {
      > li:not([class]) {
        &::before {
          left: pxToRem(-27);
        }
      }
    }
  }
}
