.js-dropdown-body {
  @include sq-transition(max-height $default-transition, visibility $fast-transition .4s);
  overflow: hidden;
  max-height: 0;
  visibility: hidden;

  .js-dropdown-disabled & {
    max-height: none;
  }

  .is-active > & {
    @include sq-transition(max-height $default-transition);
    visibility: visible;
  }
}
