// Default table theme
table {
  table-layout: fixed;
}

th,
td {
  border: 0;
  border-left: pxToRem(1) solid $white;
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);
  text-align: left;

  &:first-of-type {
    border-left-color: transparent;
  }
}

th {
  @include sq-font-size(1);
  background-color: $primary-color;
  color: $white;
  padding-bottom: pxToRem(19);
  padding-top: pxToRem(23);
}

th,
td:first-of-type {
  font-weight: 700;
  line-height: 1.25;
}

td {
  font-weight: 400;
  @include sq-font-size(.875);
  background-color: $white;
  color: $primary-color;
  padding-bottom: pxToRem(17);
  padding-top: pxToRem(18);

  &:nth-of-type(2n + 1) {
    background-color: $alabaster;
  }
}

tr {
  border-top: pxToRem(1) solid $near-white;

  &:first-of-type {
    border-top-color: transparent;
  }
}

table caption,
.e-tbl__caption {
  font-size: pxToRem(12);
  font-style: italic;
}

// Modifier
.e-tbl {
  // $bg-color is defined in squiz-module-a3-colours
  @each $theme, $bg-color in $bg-colors {
    &--#{$theme} {
      th {
        background-color: $bg-color;
      }

      tbody {
        tr {
          border-bottom: pxToRem(1) solid $bg-color;
        }
      }

      @if $theme == green or $theme == blue or $theme == navy {
        th {
          color: $white-pure;
        }
      }
    }
  }
}
