// result pagination
.c-pagination {
  min-width: pxToRem(260);
  padding: pxToRem(30) 0;
}

.c-pagination__inner {
  position: relative;
}

.c-pagination__list {
  list-style: none;
  margin: 0;
  padding: 0 pxToRem(40);
  position: relative;
  text-align: center;
}

.c-pagination__item {
  display: none;
  margin-left: pxToRem(4);

  &:first-of-type {
    margin-left: 0;
  }

  // start: hidden extra items when not enough spacing
  &.is-active,
  &:nth-last-of-type(2) {
    &::before {
      content: '...';
      display: inline-block;
      margin-right: pxToRem(4);
    }
  }
  &:first-of-type,
  &:nth-of-type(2),
  &:last-of-type {
    &::before {
      content: none;
    }
  }

  &:first-of-type,
  &:nth-of-type(2),
  &:last-of-type,
  &:nth-last-of-type(2),
  &.is-active {
    display: inline-block;
  }
  // end: hidden extra items when not enough spacing
}

.c-pagination__btn-link,
.c-pagination__link {
  @include sq-font-size(1);
}

.c-pagination__btn-link {
  font-weight: 700;
  @include sq-transition(color $default-transition);
  color: $tertiary-color;
  line-height: 1.25;

  &:hover {
    color: $primary-color;
    text-decoration: none;
  }
}

.c-pagination__link {
  color: $armadillo;
  display: inline-block;
  line-height: 1;
  padding: pxToRem(3);

  .c-pagination__item.is-active & {
    font-weight: 700;
    border-bottom: pxToRem(1) solid currentColor;
    color: $primary-color;
  }
}

.c-pagination__btn {
  @include sq-transition(transform $default-transition);
  position: absolute;
  top: pxToRem(3);
  z-index: 1;
}

.c-pagination__btn--prev {
  left: 0;

  &:hover {
    @include sq-transform(translateX(pxToRem(-5)));
  }
}

.c-pagination__btn--next {
  right: 0;

  &:hover {
    @include sq-transform(translateX(pxToRem(5)));
  }
}

.c-pagination__btn-title {
  @include sq-font-size(.875);
  color: $armadillo;
  display: none;
  line-height: 1.43;
}


// page pagination
.c-pagination--page {
  @include susy-clearfix;
  border-top: 1px solid $gainsboro;
  margin-top: pxToRem(30);
  padding: pxToRem(25) 0 pxToRem(10);

  .c-pagination__btn {
    float: left;
    position: static;
    width: 50%;
  }

  .c-pagination__btn:hover {
    @include sq-transform(translateX(0));
  }

  .c-pagination__btn-title {
    display: none;
  }

  .c-pagination__btn--next {
    direction: rtl;
  }
}
