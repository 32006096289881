// Variables

$accordion-border-color:          #ccc;
$accordion-border-style:          solid;
$accordion-border-width:          pxToRem(1);
$accordion-border:                $accordion-border-width $accordion-border-style $accordion-border-color;
$accordion-border-y:              $accordion-border;
$accordion-border-x:              none;
$accordion-color:                 $primary-color;
$accordion-item-height:           pxToRem(50);
$accordion-padding-default:       pxToRem(15);
$accordion-panel-padding:         pxToRem(20) pxToRem(15);

$accordion-panel-bg-color:        $white;
$accordion-title-bg-color:        $white;

$accordion-title-color:           $primary-color;
$accordion-active-title-bg-color: $primary-color;
$accordion-active-title-color:    $white;
$accordion-title-hover-bg-color:  darken($accordion-title-bg-color, 5%);

$accordion-tab-color:             $white;
$accordion-active-tab-color:      $base-font-colour;
$accordion-tab-bg-color:          $primary-color;
$accordion-active-tab-bg-color:   $accordion-panel-bg-color;
$accordion-tab-hover-bg-color:    darken($accordion-tab-bg-color, 10%);

$accordion-tab-border-radius:     pxToRem(6);
$accordion-tab-margin:            pxToRem(3);
$accordion-tab-opacity:           .85;
$accordion-tab-width:             auto; //100%;

$accordion-title-font-size:       1.1;
$accordion-font-size:             1;
$accordion-open-transition:       $default-transition;
$accordion-ui-transition:         $fast-transition;
$accordion-panel-transition:      $default-transition;
