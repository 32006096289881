// Variables

// Colors
$color-danger:    $scarlet;
$color-disabled:  $greyish;
$color-info:      $marine;
$color-success:   $nasty-green;
$color-warning:   $marigold;

// Settings
$btn-border-radius:       pxToRem(10);
$btn-border-radius-round: pxToRem(40);
$btn-border-width:        pxToRem(3);
$btn-color:               $white;
$btn-bg-color:            $primary-color;
$btn-font-size-default:   1;
$btn-font-size-small:     .875;
$btn-padding-default:     pxToRem(10) pxToRem(12);
$btn-padding-large:       pxToRem(12) pxToRem(20);
$btn-padding-small:       pxToRem(8) pxToRem(10);
$btn-margin-default:      0 pxToRem(20) pxToRem(20) 0;
$btn-transition:          .3s ease 0s;
$btn-lighten-modifier:    8;
$btn-darken-modifier:     12;
