/* Module: S */
.p-bottom-links {
  .footer & {
    width: 100%;
  }
}
.p-bottom-links__item {
  margin-bottom: 0;
  width: auto;
}
