// Placeholders

$hero-height-small: pxToRem(284);
$hero-height-large: pxToRem(400);

// Banner with page heading
.p-banner {
  background-position: center;
  padding: pxToRem(54) 0;
  position: relative;

  &::before {
    background-color: $secondary-color;
    content: '';
    opacity: 1;
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.p-banner__heading {
  color: $white;
  margin: 0;
  position: relative;
}

// Banner with page heading + background image
.s-banner-premium {

  .p-banner {
    background-size: cover;
    padding: pxToRem(80) 0 pxToRem(110);

    &::before {
      opacity: .4;
    }
  }

  .p-banner__heading {
    text-align: center;
  }
}

.p-banner__desc {

  /* landing page */
  .s-landing & {
    text-align: center;
    color: $white;
    font-weight: bold;
    letter-spacing: 0;
  }
}

// No banner
.s-no-banner {
  .p-banner {
    display: none;
  }
}


// landing page banner
.s-landing {
  .p-banner {
    height: $hero-height-small;
    @media #{$mq-medium} {
      height: $hero-height-large;
    }

    &__heading {
      font-size: pxToRem(30);
      &::after {
        background-color: $iron;
        bottom: pxToRem(-14);
        content: '';
        height: pxToRem(3);
        left: 0;
        position: absolute;
        width: pxToRem(30);
      }
      @media #{$mq-medium} {
        font-size: pxToRem(64);
        line-height: 1.25;
        letter-spacing: 0;
       }

      &:after {
        left: 44%;
        background-color: $black;
        opacity: .6;
        @media #{$mq-medium} {
           left: 48%;
        }
      }
    }

    &__desc {
      font-size: pxToRem(14);
      font-weight: 700;
      position: relative;
      margin-top: pxToRem(45);
      @media #{$mq-medium} {
        margin-top: pxToRem(45);
        font-size: pxToRem(22);

      }
    }
  }
}
